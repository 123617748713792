import { IconChannel } from '@/assets/svg';
import { TAB_TIME_SERIES_PLOTS } from '@/components/TimeSeriesPlots/constants/enum';
import { useTSPContext } from '@/components/TimeSeriesPlots/context/timeSeriesPlotsContext';
import useClickOutside from '@/hooks/useClickOutside.hook';
import { forwardRef, useRef, useState } from 'react';
import { AverageLevelControl } from './averageLevelControl';
import { SwitchsChannel } from './switchsChannel';

export function ChannelMenuTimeSeriesPlots() {
  const [showModal, setShowModal] = useState(false);
  const { currentChannels, handleToggleChannel, currentTab } = useTSPContext();

  const refModal = useRef<HTMLDivElement>(null);

  const handleToggleModal = () => setShowModal(!showModal);
  useClickOutside(refModal, () => setShowModal(false));

  return (
    <div className="relative" ref={refModal}>
      <div className="p-[2px] border text-gray-300-electripure border-gray-200-electripure rounded-sm ml-2">
        <IconChannel onClick={handleToggleModal} className="size-6" />
      </div>

      <ModalChannel show={showModal} currentTab={currentTab}>
        <SwitchsChannel
          channels={currentChannels}
          currentTab={currentTab}
          handleToggleChannel={(code) => handleToggleChannel?.(code)}
        />
        <AverageLevelControl />
      </ModalChannel>
    </div>
  );
}

interface Props {
  show: boolean;
  children: any;
  currentTab: TAB_TIME_SERIES_PLOTS | undefined;
}

const ModalChannel = forwardRef(function ModalChannel(
  { show, children, currentTab }: Props,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  return (
    <div
      ref={ref}
      className={`${
        currentTab === TAB_TIME_SERIES_PLOTS.VOLTAGE_CURRENT
          ? 'w-[520PX]'
          : 'w-[400PX]'
      } left-0 z-10 xl:right-0 xl:left-auto  h-auto min-h-[200px] flex justify-center flex-col items-center rounded-lg drop-shadow-md absolute bg-white mt-1  border-gray-500-electripure ${
        show ? '' : 'hidden'
      }`}>
      <div className="w-full h-auto bg-color-white rounded max-h-full relative flex flex-col gap-4 p-4">
        {children}
      </div>
    </div>
  );
});
