import { AVERAGE_LEVEL } from './enum';

export const averageModeOptions = [
  {
    label: 'None',
    value: AVERAGE_LEVEL.NONE
  },
  {
    label: '15 Minutes',
    value: AVERAGE_LEVEL.FIFTEEN_MINUTES
  },
  {
    label: '30 Minutes',
    value: AVERAGE_LEVEL.THIRTY_MINUTES
  },
  {
    label: '60 Minutes',
    value: AVERAGE_LEVEL.SIXTY_MINUTES
  },
  {
    label: '6 Hours',
    value: AVERAGE_LEVEL.SIX_HOURS
  },
  {
    label: '12 Hours',
    value: AVERAGE_LEVEL.TWELVE_HOURS
  },
  {
    label: '24 Hours',
    value: AVERAGE_LEVEL.TWENTY_FOUR_HOURS
  }
];
