import { ElectripureState } from '@/interfaces/reducers';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { BodyTimeSeriesPlots } from './components/BodyTimeSeriesPlots';
import { HeaderTimeSeriesPlots } from './components/HeaderTimeSeriesPlots';
import { TSPContextProvider } from './context/timeSeriesPlotsContext';

export function TimeSeriesPlots() {
  const [companyName, setCompanyName] = useState('');
  const [siteName, setSiteName] = useState('');
  const [meterName, setMeterName] = useState('');

  const { meterId } = useParams();

  const companies = JSON.parse(
    useSelector((state: ElectripureState) => state.companies)
  );

  console.log({ meterId });

  const handleFoundMeter = () => {
    for (let i = 0; i <= companies?.length; i++) {
      const listSites: any[] = companies?.[i]?.['list_sites'] ?? [];
      for (let j = 0; j <= listSites?.length; j++) {
        const site = listSites?.[j] ?? {};
        const meter = site?.['mdp_list']?.find(
          (mdp: any) => mdp?.meter_id == meterId
        );
        if (meter) {
          setCompanyName(companies[i].company_name);
          setSiteName(site.name);
          setMeterName(meter.name);
          break;
        }
      }

      if (meterName) {
        break;
      }
    }
  };

  useEffect(() => {
    if (meterId && companies.length > 0) {
      handleFoundMeter();
    }
  }, [meterId]);

  return (
    <TSPContextProvider>
      <div className="border-b-[1px] border-[#f5f5f5]  ">
        <div className="h-[30px] flex items-center   gap-x-2">
          <h3 className="f-bold text-lg text-gray-300-electripure 	">
            Time Series Plots
          </h3>
          <div className="flex items-center">
            {companyName}, &thinsp;
            {siteName}, &thinsp;
            {meterName}
          </div>
        </div>
        <div
          style={{ minHeight: 'calc(100% - 30px)' }}
          className="flex flex-col w-full rounded border-color-secondary border bg-white p-[30px] mb-8">
          <HeaderTimeSeriesPlots />
          <BodyTimeSeriesPlots />
        </div>
      </div>
    </TSPContextProvider>
  );
}
