import { SwitchChannel } from '../types/channel';
import {
  CURRENT_HARMONICS_CHANNEL,
  POWER_CHANNEL,
  TAB_TIME_SERIES_PLOTS,
  VOLTAGE_CURRENT_CHANNEL,
  VOLTAGE_HARMONICS_CHANNEL
} from './enum';

export const initialStateChannels: {
  [key: string]: SwitchChannel[];
} = {
  [TAB_TIME_SERIES_PLOTS.VOLTAGE_CURRENT]: [
    {
      code: VOLTAGE_CURRENT_CHANNEL.A,
      keys: ['curr_a', 'volt_a'],
      color: 'text-[#000000]',
      bgColor: 'peer-checked:bg-[#000000]',
      isActive: true,
      label: VOLTAGE_CURRENT_CHANNEL.A
    },

    {
      code: VOLTAGE_CURRENT_CHANNEL.B,
      keys: ['curr_b', 'volt_b'],
      color: 'text-[#DF3C25]',
      bgColor: 'peer-checked:bg-[#DF3C25]',
      isActive: true,
      label: VOLTAGE_CURRENT_CHANNEL.B
    },

    {
      code: VOLTAGE_CURRENT_CHANNEL.C,
      keys: ['curr_c', 'volt_c'],
      color: 'text-[#006FF9]',
      bgColor: 'peer-checked:bg-[#006FF9]',
      isActive: true,
      label: VOLTAGE_CURRENT_CHANNEL.C
    },
    {
      code: VOLTAGE_CURRENT_CHANNEL.N,
      keys: ['curr_n', 'volt_n'],
      color: 'text-[#707070]',
      bgColor: 'peer-checked:bg-[#707070]',
      isActive: true,
      label: VOLTAGE_CURRENT_CHANNEL.N
    },
    {
      code: VOLTAGE_CURRENT_CHANNEL.MIN_A,
      keys: ['curr_a_min', 'volt_a_min'],
      color: 'text-[#000000]',
      bgColor: 'peer-checked:bg-[#000000]',
      isActive: false,
      label: VOLTAGE_CURRENT_CHANNEL.MIN_A
    },
    {
      code: VOLTAGE_CURRENT_CHANNEL.MAX_A,
      keys: ['curr_a_max', 'volt_a_max'],
      color: 'text-[#000000]',
      bgColor: 'peer-checked:bg-[#000000]',
      isActive: false,
      label: VOLTAGE_CURRENT_CHANNEL.MAX_A
    },
    {
      code: VOLTAGE_CURRENT_CHANNEL.MIN_B,
      keys: ['curr_b_min', 'volt_b_min'],
      color: 'text-[#DF3C25]',
      bgColor: 'peer-checked:bg-[#DF3C25]',
      isActive: false,
      label: VOLTAGE_CURRENT_CHANNEL.MIN_B
    },
    {
      code: VOLTAGE_CURRENT_CHANNEL.MAX_B,
      keys: ['curr_b_max', 'volt_b_max'],
      color: 'text-[#DF3C25]',
      bgColor: 'peer-checked:bg-[#DF3C25]',
      isActive: false,
      label: VOLTAGE_CURRENT_CHANNEL.MAX_B
    },
    {
      code: VOLTAGE_CURRENT_CHANNEL.MIN_C,
      keys: ['curr_c_min', 'volt_c_min'],
      color: 'text-[#006FF9]',
      bgColor: 'peer-checked:bg-[#006FF9]',
      isActive: false,
      label: VOLTAGE_CURRENT_CHANNEL.MIN_C
    },
    {
      code: VOLTAGE_CURRENT_CHANNEL.MAX_C,
      keys: ['curr_c_max', 'volt_c_max'],
      color: 'text-[#006FF9]',
      bgColor: 'peer-checked:bg-[#006FF9]',
      isActive: false,
      label: VOLTAGE_CURRENT_CHANNEL.MAX_C
    },

    {
      code: VOLTAGE_CURRENT_CHANNEL.PHASE_AVERAGE,
      color: 'text-[#707070]',
      bgColor: 'peer-checked:bg-[#707070]',
      keys: ['avg_curr', 'avg_volt'],
      isActive: false,
      label: VOLTAGE_CURRENT_CHANNEL.PHASE_AVERAGE
    },
    {
      code: VOLTAGE_CURRENT_CHANNEL.IMBALANCE,
      keys: ['imbalance_curr', 'imbalance_volt'],
      color: 'text-[#FC8517]',
      bgColor: 'peer-checked:bg-[#FC8517]',
      isActive: false,
      label: VOLTAGE_CURRENT_CHANNEL.IMBALANCE
    }
  ],
  [TAB_TIME_SERIES_PLOTS.POWER]: [
    {
      code: POWER_CHANNEL.PF,
      keys: ['pf_average', 'pf_a', 'pf_b', 'pf_c'],
      color: 'text-[#006FF9]',
      bgColor: 'peer-checked:bg-[#006FF9]',
      isActive: true,
      label: POWER_CHANNEL.PF
    },
    {
      code: POWER_CHANNEL.KW,
      keys: ['kw_average', 'kw_a', 'kw_b', 'kw_c'],
      color: 'text-[#55BA47]',
      bgColor: 'peer-checked:bg-[#55BA47]',
      isActive: true,
      label: POWER_CHANNEL.KW
    },
    {
      code: POWER_CHANNEL.KVAR,
      keys: ['kvar_average', 'kvar_a', 'kvar_b', 'kvar_c'],
      color: 'text-[#DF3C25]',
      bgColor: 'peer-checked:bg-[#DF3C25]',
      isActive: true,
      label: POWER_CHANNEL.KVAR
    },
    {
      code: POWER_CHANNEL.KVA,
      keys: ['kva_average', 'kva_a', 'kva_b', 'kva_c'],
      color: 'text-[#FC8517]',
      bgColor: 'peer-checked:bg-[#FC8517]',
      isActive: true,
      label: POWER_CHANNEL.KVA
    }
  ],
  [TAB_TIME_SERIES_PLOTS.VOLTAGE_HARMONICS]: [
    {
      code: VOLTAGE_HARMONICS_CHANNEL.THD,
      keys: ['thdv_a', 'thdv_b', 'thdv_c'],
      color: 'text-[#4C4C4C]',
      bgColor: 'peer-checked:bg-[#4C4C4C]',
      isActive: true,
      label: VOLTAGE_HARMONICS_CHANNEL.THD
    },
    {
      code: VOLTAGE_HARMONICS_CHANNEL.$3RD,
      keys: ['h3v_a', 'h3v_b', 'h3v_c'],
      color: 'text-[#1F77B4]',
      bgColor: 'peer-checked:bg-[#1F77B4]',
      isActive: true,
      label: VOLTAGE_HARMONICS_CHANNEL.$3RD
    },
    {
      code: VOLTAGE_HARMONICS_CHANNEL.$5TH,
      keys: ['h5v_a', 'h5v_b', 'h5v_c'],
      color: 'text-[#FF7F0E]',
      bgColor: 'peer-checked:bg-[#FF7F0E]',
      isActive: true,
      label: VOLTAGE_HARMONICS_CHANNEL.$5TH
    },
    {
      code: VOLTAGE_HARMONICS_CHANNEL.$7TH,
      keys: ['h7v_a', 'h7v_b', 'h7v_c'],
      color: 'text-[#2CA02C]',
      bgColor: 'peer-checked:bg-[#2CA02C]',
      isActive: true,
      label: VOLTAGE_HARMONICS_CHANNEL.$7TH
    },
    {
      code: VOLTAGE_HARMONICS_CHANNEL.$9TH,
      keys: ['h9v_a', 'h9v_b', 'h9v_c'],
      color: 'text-[#D62728]',
      bgColor: 'peer-checked:bg-[#D62728]',
      isActive: false,
      label: VOLTAGE_HARMONICS_CHANNEL.$9TH
    },
    {
      code: VOLTAGE_HARMONICS_CHANNEL.$11TH,
      keys: ['h11v_a', 'h11v_b', 'h11v_c'],
      color: 'text-[#9467BD]',
      bgColor: 'peer-checked:bg-[#9467BD]',
      isActive: false,
      label: VOLTAGE_HARMONICS_CHANNEL.$11TH
    },
    {
      code: VOLTAGE_HARMONICS_CHANNEL.$13TH,
      keys: ['h13v_a', 'h13v_b', 'h13v_c'],
      color: 'text-[#8C564B]',
      bgColor: 'peer-checked:bg-[#8C564B]',
      isActive: false,
      label: VOLTAGE_HARMONICS_CHANNEL.$13TH
    },
    {
      code: VOLTAGE_HARMONICS_CHANNEL.$15TH,
      keys: ['h15v_a', 'h15v_b', 'h15v_c'],
      color: 'text-[#E377C2]',
      bgColor: 'peer-checked:bg-[#E377C2]',
      isActive: false,
      label: VOLTAGE_HARMONICS_CHANNEL.$15TH
    }
  ],
  [TAB_TIME_SERIES_PLOTS.CURRENT_HARMONICS]: [
    {
      code: CURRENT_HARMONICS_CHANNEL.THD,
      keys: ['thdc_a', 'thdc_b', 'thdc_c', 'thdc_n'],
      color: 'text-[#4C4C4C]',
      bgColor: 'peer-checked:bg-[#4C4C4C]',
      isActive: true,
      label: CURRENT_HARMONICS_CHANNEL.THD
    },
    {
      code: CURRENT_HARMONICS_CHANNEL.$3RD,
      keys: ['h3c_a', 'h3c_b', 'h3c_c', 'h3c_n'],
      color: 'text-[#1F77B4]',
      bgColor: 'peer-checked:bg-[#1F77B4]',
      isActive: true,
      label: CURRENT_HARMONICS_CHANNEL.$3RD
    },
    {
      code: CURRENT_HARMONICS_CHANNEL.$5TH,
      keys: ['h5c_a', 'h5c_b', 'h5c_c', 'h5c_n'],
      color: 'text-[#FF7F0E]',
      bgColor: 'peer-checked:bg-[#FF7F0E]',
      isActive: true,
      label: CURRENT_HARMONICS_CHANNEL.$5TH
    },
    {
      code: CURRENT_HARMONICS_CHANNEL.$7TH,
      keys: ['h7c_a', 'h7c_b', 'h7c_c', 'h7c_n'],
      color: 'text-[#2CA02C]',
      bgColor: 'peer-checked:bg-[#2CA02C]',
      isActive: true,
      label: CURRENT_HARMONICS_CHANNEL.$7TH
    },
    {
      code: CURRENT_HARMONICS_CHANNEL.$9TH,
      keys: ['h9c_a', 'h9c_b', 'h9c_c', 'h9c_n'],
      color: 'text-[#D62728]',
      bgColor: 'peer-checked:bg-[#D62728]',
      isActive: false,
      label: CURRENT_HARMONICS_CHANNEL.$9TH
    },
    {
      code: CURRENT_HARMONICS_CHANNEL.$11TH,
      keys: ['h11c_a', 'h11c_b', 'h11c_c', 'h11c_n'],
      color: 'text-[#9467BD]',
      bgColor: 'peer-checked:bg-[#9467BD]',
      isActive: false,
      label: CURRENT_HARMONICS_CHANNEL.$11TH
    },
    {
      code: CURRENT_HARMONICS_CHANNEL.$13TH,
      keys: ['h13c_a', 'h13c_b', 'h13c_c', 'h13c_n'],
      color: 'text-[#8C564B]',
      bgColor: 'peer-checked:bg-[#8C564B]',
      isActive: false,
      label: CURRENT_HARMONICS_CHANNEL.$13TH
    },
    {
      code: CURRENT_HARMONICS_CHANNEL.$15TH,
      keys: ['h15c_a', 'h15c_b', 'h15c_c', 'h15c_n'],
      color: 'text-[#E377C2]',
      bgColor: 'peer-checked:bg-[#E377C2]',
      isActive: false,
      label: CURRENT_HARMONICS_CHANNEL.$15TH
    }
  ]
};

export const initialTabs = [
  {
    name: 'Voltage & Current',
    code: TAB_TIME_SERIES_PLOTS.VOLTAGE_CURRENT,
    abbrevation: 'vc'
  },
  {
    name: 'Power',
    code: TAB_TIME_SERIES_PLOTS.POWER,
    abbrevation: 'power'
  },
  {
    name: 'Voltage Harmonics',
    code: TAB_TIME_SERIES_PLOTS.VOLTAGE_HARMONICS,
    abbrevation: 'vh'
  },
  {
    name: 'Current Harmonics',
    code: TAB_TIME_SERIES_PLOTS.CURRENT_HARMONICS,
    abbrevation: 'ch'
  }
];
