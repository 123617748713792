import { ResponseGeneric } from '../interfaces/base-service';

export class BaseService {
  static jsonToQueryParams(data: any): string {
    let params: string[] = Object.keys(data).map((key: string): string => {
      return `${key}=${data[key]}`;
    });
    return params.join('&');
  }

  static requestPost(
    url: string,
    body: any,
    headers: any = {},
    signal?: any
  ): Promise<ResponseGeneric<any>> {
    return new Promise((resolve, reject) => {
      fetch(url, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          ...headers
        },
        body: JSON.stringify(body),
        signal: signal
      })
        .then((response) => {
          const status = response.status;
          let data: any = null;
          let error: string | null = null;
          response
            .json()
            .then((json) => {
              data = json;
              resolve({
                data: data,
                statusCode: status,
                success: true,
                error: null
              } as ResponseGeneric<any>);
            })
            .catch((err) => {
              error = err;
              reject({
                data: null,
                statusCode: status,
                success: false,
                error: error
              });
            });
        })
        .catch((err: any) => {
          console.log('Fetch error: ', err);
          reject({
            data: null,
            statusCode: null,
            success: false,
            error: err
          });
        });
    });
  }

  static requestGet(
    url: string,
    body: FormData,
    headers: any = {},
    signal?: any
  ): Promise<ResponseGeneric<any>> {
    return new Promise((resolve, reject) => {
      fetch(url, {
        method: 'GET',
        headers: {
          // 'Content-Type': 'application/x-www-form-urlencoded',
          ...headers
        },
        body: body,
        signal: signal
      })
        .then((response) => {
          const status = response.status;
          let data: any = null;
          let error: string | null = null;
          response
            .json()
            .then((json) => {
              data = json;
              resolve({
                data: data,
                statusCode: status,
                success: true,
                error: null
              } as ResponseGeneric<any>);
            })
            .catch((err) => {
              error = err;
              reject({
                data: null,
                statusCode: status,
                success: false,
                error: error
              });
            });
        })
        .catch((err: any) => {
          console.log('Fetch error: ', err);
          reject({
            data: null,
            statusCode: null,
            success: false,
            error: err
          });
        });
    });
  }

  static requestPut(
    url: string,
    body: FormData,
    headers: any = {},
    signal?: any
  ): Promise<ResponseGeneric<any>> {
    return new Promise((resolve, reject) => {
      fetch(url, {
        method: 'PUT',
        headers: {
          // 'Content-Type': 'application/x-www-form-urlencoded',
          ...headers
        },
        body: body,
        signal: signal
      })
        .then((response) => {
          const status = response.status;
          let data: any = null;
          let error: string | null = null;
          response
            .json()
            .then((json) => {
              data = json;
              resolve({
                data: data,
                statusCode: status,
                success: true,
                error: null
              } as ResponseGeneric<any>);
            })
            .catch((err) => {
              error = err;
              reject({
                data: null,
                statusCode: status,
                success: false,
                error: error
              });
            });
        })
        .catch((err: any) => {
          console.log('Fetch error: ', err);
          reject({
            data: null,
            statusCode: null,
            success: false,
            error: err
          });
        });
    });
  }
}
