import { showToast } from '@/actions/electripure';
import { IconCross } from '@/assets/svg';
import { useTSPContext } from '@/components/TimeSeriesPlots/context/timeSeriesPlotsContext';
import { TimeRange } from '@/components/TimeSeriesPlots/types/date-range';
import useClickOutside from '@/hooks/useClickOutside.hook';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs, { Dayjs } from 'dayjs';
import { forwardRef, MouseEventHandler, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

export function TimeRangeCompany() {
  const [showModal, setShowModal] = useState(false);
  const [isOpenDateTimePiker, setIsOpenDateTimePiker] = useState(false);
  const dispatch = useDispatch();

  const { timeRange, setTimeRange, resetZoom } = useTSPContext();

  const [startTime, setStartTime] = useState<Date | Dayjs | null>(
    timeRange?.startTime ? dayjs(timeRange?.startTime) : new Date()
  );
  const [endTime, setEndTime] = useState<Date | Dayjs | null>(
    timeRange?.endTime ? dayjs(timeRange?.endTime) : new Date()
  );

  const handleStartTimeChange = (newValue: Dayjs | null) => {
    setStartTime(newValue);
  };

  const handleEndTimeChange = (newValue: Dayjs | null) => {
    setEndTime(newValue);
  };

  const refModal = useRef<HTMLDivElement>(null);
  useClickOutside(refModal, ({ element }) => {
    let isMuiPickers = false;
    element.classList.forEach((classValue) => {
      if (
        classValue.includes('MuiPickers') ||
        classValue.includes('MuiMultiSectionDigitalClockSection') ||
        classValue.includes('MuiButton') ||
        classValue.includes('MuiDayCalendar') ||
        classValue.includes('MuiDateCalendar')
      ) {
        isMuiPickers = true;
      }
    });

    if (isOpenDateTimePiker && !isMuiPickers) {
      setIsOpenDateTimePiker(false);
      return;
    }

    if (!isMuiPickers) setShowModal(false);
  });

  const onSubmitModal = () => {
    const diffInMonths = dayjs(endTime).diff(dayjs(startTime), 'month', true);

    if (diffInMonths > 3) {
      dispatch(
        showToast({
          message: 'Dates must be less than 3 months.',
          status: 'error'
        }) as any
      );
    }

    const strStartTime = startTime ? dayjs(startTime).toISOString() : null;
    const strEndTime = endTime ? dayjs(endTime).toISOString() : null;

    resetZoom?.()
    setTimeRange?.({
      startTime: strStartTime,
      endTime: strEndTime
    } as TimeRange);
  };

  return (
    <div ref={refModal}>
      <button
        onClick={() => setShowModal(true)}
        className="bg-blue-300-electripure color-white font-semibold px-2 py-1 rounded-lg relative">
        Set Time Range
      </button>
      <ModalRangeDateTime
        show={showModal}
        onClickModal={onSubmitModal}
        onClose={() => {
          setShowModal(false);
        }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={['DateTimePicker']}>
            <div className="relative z-20 flex flex-col gap-y-3">
              <div>
                <span className="text-sm">From</span>
                <DateTimePicker
                  defaultValue={startTime ? dayjs(startTime) : null}
                  onChange={(newValue) => handleStartTimeChange(newValue)}
                  maxDateTime={endTime ? dayjs(endTime) : dayjs()}
                  onOpen={() => setIsOpenDateTimePiker(true)}
                />
              </div>
              <div>
                <span className="text-sm">To</span>
                <DateTimePicker
                  defaultValue={endTime ? dayjs(endTime) : null}
                  onChange={(newValue) => handleEndTimeChange(newValue)}
                  minDateTime={startTime ? dayjs(startTime) : dayjs()}
                  maxDateTime={dayjs()}
                  onOpen={() => setIsOpenDateTimePiker(true)}
                />
              </div>
            </div>
          </DemoContainer>
        </LocalizationProvider>
      </ModalRangeDateTime>
    </div>
  );
}

interface Props {
  show: boolean;
  children: any;
  onClose: MouseEventHandler;
  onClickModal: () => void;
}

const ModalRangeDateTime = forwardRef(function ModalRangeDateTime(
  { show, children, onClose, onClickModal }: Props,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  return (
    <div
      ref={ref}
      className={`w-[300px] h-[320px]  flex justify-center flex-col items-center rounded-lg drop-shadow-md border-gray-500-electripure absolute bg-white mt-1 px-10 z-10 ${
        show ? '' : 'hidden'
      }`}>
      <div className="h-auto bg-color-white rounded overflow-y-auto max-h-full relative flex flex-col gap-4">
        {children}
      </div>
      <span className="absolute top-2 right-2 cursor-pointer" onClick={onClose}>
        <IconCross className="size-6" />
      </span>
      <button
        className="bg-blue-100-electripure w-full mt-5 color-white font-semibold  py-3 rounded-lg"
        onClick={onClickModal}>
        Search
      </button>
    </div>
  );
});
