import { setLoading, showToast } from '@/actions/electripure';
import {
  NAVEGATION_STATE,
  TYPE_EVENTS_EVENT,
  TYPE_EVENTS_ZOOM
} from '@/config/enum';
import { ChartContext } from '@/context/GraphContext';
import { NavContext } from '@/context/NavContext';
import { GetChartData } from '@/interfaces/electripure-service';
import {
  AxisConfigDict,
  ChannelConfig,
  ChannelDataDictPowerLog,
  ChannelDataPowerLog,
  ChannelOptionDictPowerLog,
  ChannelOptionPowerLog,
  GroupOptionDictPowerLog,
  GroupOptionPowerLog,
  LabelOptionPowerLog,
  PowerLogConfig,
  YConfig,
  YDataOptionDictPowerLog,
  YDataOptionPowerLog,
  ZoomPowerLog
} from '@/interfaces/graph';
import ElectripureService from '@/service/electripure-service';
import { toUnix } from '@/utils/parser';
import moment from 'moment-timezone';
import {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { useDispatch } from 'react-redux';
import Space from '../../Space';
import ChannelControl from '../ChannelControl';
import DateRangeControlCustom2 from '../DateRangeControlCustom2';
import EventMenu from '../EventMenu';
import GroupControl from '../GroupControl';
import GroupMenu, { yDataControl } from '../GroupMenu';
import ZoomMenu from '../ZoomMenu';
import BuildGraph from './BuildGraph';

const initStateData: ChannelDataPowerLog = {
  x: [],
  timestamp: [],
  x_label: [],
  y: {},
  backgroundRanges: [],
  y_type_point: {}
};
// SECTION Generate a init state of the data dict by channel for power log using channel keys
function generateInitStateChannelDataDictPowerLog(
  configList: ChannelConfig[],
  ChannelDataPowerLog: ChannelDataPowerLog
): ChannelDataDictPowerLog {
  let dict: ChannelDataDictPowerLog = {};
  configList.map((config: ChannelConfig) => {
    dict[config.key] = ChannelDataPowerLog;
  });
  return dict;
}
// !SECTION

// SECTION Generate a init state of the option dict by channel for power log using channel keys
function generateInitStateChannelOptionDictPowerLog(
  configList: ChannelConfig[]
): ChannelOptionDictPowerLog {
  let dict: ChannelOptionDictPowerLog = {};
  configList.map((config: ChannelConfig, index: number) => {
    // NOTE For default show the fisrt channel
    const option: ChannelOptionPowerLog = {
      isShow: index == 0,
      key: config.key,
      label: config.label
    };
    dict[config.key] = option;
  });
  return dict;
}
// !SECTION

// SECTION Generate a init state of the option dict by "Y" data for channel using Y key
function generateInitStateYDataOptionDictPowerLog(
  configList: YConfig[]
): YDataOptionDictPowerLog {
  let dict: any = {};
  configList.map((config: YConfig, index: number) => {
    const option: YDataOptionPowerLog = {
      key: config.key,
      axisGroup: config.axisGroup,
      label: config.labelShort,
      isShow: true,
      groupList: config.groupList,
      isShowByGroup: config.groupList.map((group: string) => true),
      color: config.color
    };
    dict[config.key] = option;
  });
  return dict;
}

// SECTION Generate a init state of the option dict by group using group and labels in each "y"
function generateInitStateGroupOptionDictPowerLog(
  configList: YConfig[]
): GroupOptionDictPowerLog {
  let dict: GroupOptionDictPowerLog = {};
  configList.map((config: YConfig, index: number) => {
    if (config.hasOwnProperty('groupList') && Array.isArray(config.groupList)) {
      config.groupList.map((group: string, index: number) => {
        // Create group if not exists
        if (!dict.hasOwnProperty(group)) {
          dict[group] = {
            labels: {},
            name: group
          };
        }
        // Create label into group if not exists
        if (!dict[group].labels.hasOwnProperty(config.labelList[index])) {
          dict[group].labels[config.labelList[index]] = {
            name: config.labelList[index],
            groupName: group,
            isShow: true,
            yKeyList: []
          };
        }
        // Add "y" key to label
        dict[group].labels[config.labelList[index]].yKeyList.push(config.key);
      });
    }
  });
  return dict;
}
// !SECTION

interface IPowerLogGraph {
  deviceId: number;
  config: PowerLogConfig;
  toData: (channel: ChannelConfig, result: any) => ChannelDataPowerLog; //(result: any) => ChannelDataPowerLog;
  channelConfigList: ChannelConfig[];
  yConfigList: YConfig[];
  axisConfigDict: AxisConfigDict;
}

function PowerLogGraph({
  deviceId,
  toData,
  config,
  yConfigList,
  channelConfigList,
  axisConfigDict
}: IPowerLogGraph) {
  const dispatch = useDispatch();
  const [timeRange, setTimeRange] = useState(0);

  const getMinutesBetweenDates = (
    startDate: string,
    endDate: string
  ): number => {
    const date1 = new Date(startDate).getTime();
    const date2 = new Date(endDate).getTime();
    const msPerMinute = 1000 * 60;
    const diffInMs = date2 - date1;
    return Math.floor(diffInMs / msPerMinute);
  };

  const getSecondsBetweenDates = (
    startDate: string,
    endDate: string
  ): number => {
    const date1 = new Date(startDate).getTime();
    const date2 = new Date(endDate).getTime();
    const msPerSecond = 1000;
    const diffInMs = date2 - date1;
    return Math.floor(diffInMs / msPerSecond);
  };

  const getLastHourMinutes = (stringDate: string): number => {
    const date = new Date(stringDate);
    const minutes = date.getMinutes();
    const hours = date.getHours();
    return hours * 60 + minutes;
  };

  const process_data_per_range = async () => {
    console.log('ANTES : channelDataDict', { ...originalChannelDataDict });
    var newChannelDataDict = { ...originalChannelDataDict };
    var keys = Object.keys(originalChannelDataDict);
    if (averageMode == 0) {
      setChannelDataDict(originalChannelDataDict);
      return;
    }
    for (let ____index = 0; ____index < keys.length; ____index++) {
      const data: ChannelDataPowerLog =
        originalChannelDataDict[keys[____index]];
      console.log('ENTRO A PROCESS_X');
      var len = data.x.length;
      console.log('len', len);
      var seconds: number = 0;
      var _theIndexes: number[] = [];
      // var _theX: string[] = [];
      var newData: ChannelDataPowerLog = {
        x: [],
        timestamp: [],
        x_label: [],
        y: {},
        backgroundRanges: [],
        y_type_point: {}
      };
      for (let index = 0; index < len; index++) {
        if (index < len - 1) {
          seconds =
            seconds + getSecondsBetweenDates(data.x[index], data.x[index + 1]);
          // console.log('minutes', seconds);
          if (seconds >= averageMode * 60) {
            _theIndexes.push(index + 1);
            newData.x.push(data.x[index + 1]);
            newData.timestamp.push(data.timestamp[index + 1]);
            seconds = 0;
          }
        }
        if (index == len - 1) {
          var myIndex: number = 0;
          var y_keys = Object.keys(data.y);
          var y_len = y_keys.length;

          for (let _index = 0; _index < y_len; _index++) {
            myIndex = 0;
            for (
              let __index = 0;
              __index < data.y[y_keys[_index]].length;
              __index++
            ) {
              const element = data.y[y_keys[_index]][__index];
              if (newData.y[y_keys[_index]] == undefined) {
                newData.y[y_keys[_index]] = [];
              }
              if (_theIndexes.includes(__index)) {
                if (__index == 0) {
                  newData.y[y_keys[_index]].push(element);
                } else {
                  var max = Math.max(
                    ...data.y[y_keys[_index]].slice(myIndex, __index)
                  );
                  newData.y[y_keys[_index]].push(max);
                }
                myIndex = __index;
              }
            }
          }

          for (let _index = 0; _index < data.x_label.length; _index++) {
            if (_theIndexes.includes(_index)) {
              newData.x_label.push(data.x_label[_index]);
            }
          }

          for (let _index = 0; _index < y_keys.length; _index++) {
            for (
              let __index = 0;
              __index < data.y_type_point[y_keys[_index]].length;
              __index++
            ) {
              if (newData.y_type_point[y_keys[_index]] === undefined) {
                newData.y_type_point[y_keys[_index]] = [];
              }
              if (_theIndexes.includes(__index)) {
                newData.y_type_point[y_keys[_index]].push(
                  data.y_type_point[y_keys[_index]][__index]
                );
              }
            }
          }

          // console.log('newData', newData);
          // var _channelDataDict = { ...channelDataDict };
          // _channelDataDict[target_key] = newData;

          // channelDataDict[target_key] = newData;
          // setChannelDataDict((prevData) => ({
          //   ...prevData,
          //   [keys[____index]]: newData
          // }));
          newChannelDataDict[keys[____index]] = newData;
        }
      }
    }
    setChannelDataDict(newChannelDataDict);
    console.log('DESPUES : channelDataDict', { ...channelDataDict });
    // setRawChannelDataDict(JSON.stringify(channelDataDict));
    // setTheIndexes(_theIndexes);
    // console.log('theIndexes', _theIndexes);
    // console.log('theX', _theX);
  };

  const {
    isLiveMode,
    setIsLiveMode,
    startDateFilter,
    setStartDateFilter,
    endDateFilter,
    setEndDateFilter,
    areDatesValid,
    chart,
    averageMode
  } = useContext(ChartContext);

  const [triggerRefresh, setTriggerRefresh] = useState<boolean>(false);

  useEffect(() => {
    if (triggerRefresh) {
      fetchData();
      setTriggerRefresh(false);
    }
  }, [triggerRefresh]);

  // Spiking too much data in a short amount of time
  // useEffect(() => {
  //   let intervalId: NodeJS.Timeout;
  //   let controllers: AbortController[] = [];

  //   if (isLiveMode)
  //     intervalId = setInterval(async () => {
  //       const endDate = new Date().getTime();

  //       const startDate = endDate - 86400000;
  //       try {
  //         await Promise.all(
  //           channelConfigList.map(
  //             async (channelConfig: ChannelConfig, index: number) => {
  //               const controller = new AbortController();
  //               const signal = controller.signal;
  //               console.log(signal);
  //               const payload = {
  //                 date_max: toUnix(endDate),
  //                 date_min: toUnix(startDate),
  //                 device: deviceId,
  //                 points: null
  //               };
  //               controllers.push(controller);
  //               const response: ResponseGeneric =
  //                 await ElectripureService.getDataGraph(
  //                   channelConfig.url,
  //                   payload,
  //                   signal
  //                 );

  //               if (!response.success) {
  //                 dispatch(
  //                   showToast({
  //                     message: response.error!,
  //                     status: 'error'
  //                   })
  //                 );
  //                 // throw new Error(
  //                 //   'Problemas al obtener la data de los graficos.'
  //                 // );
  //               }
  //               channelDataDict[channelConfig.key] = toData(
  //                 channelConfig,
  //                 response.data
  //               );
  //             }
  //           )
  //         );
  //       } catch (e: any) {
  //         console.error(e);
  //         dispatch(
  //           showToast({
  //             message: e.message,
  //             status: 'error'
  //           })
  //         );
  //       } finally {
  //         dispatch(
  //           setLoading({
  //             loading: false
  //           })
  //         );
  //       }

  //       setRawChannelDataDict(JSON.stringify(channelDataDict));
  //     }, 15000);

  //   return () => {
  //     if (isLiveMode) {
  //       clearInterval(intervalId);
  //       controllers.map((controller) => {
  //         console.log('aborting controller');
  //         controller.abort();
  //       });
  //       controllers = [];
  //     }
  //   };
  // }, [isLiveMode]);

  const toggleTimeRange = (event: Event, newValue: number | number[]) => {
    setTimeRange(newValue as number);
  };

  // useEffect(() => {
  //   process_data_per_range();
  // }, [timeRange]);

  useEffect(() => {
    process_data_per_range();
  }, [averageMode]);

  const { sideNavRef, setStateNavegation, collapsed } = useContext(NavContext);

  const [rawChannelDataDict, setRawChannelDataDict] = useState(
    JSON.stringify(
      generateInitStateChannelDataDictPowerLog(channelConfigList, initStateData)
    )
  );
  // const channelDataDict: ChannelDataDictPowerLog = JSON.parse(rawChannelDataDict);
  const [channelDataDict, setChannelDataDict] = useState(
    generateInitStateChannelDataDictPowerLog(channelConfigList, initStateData)
  );
  const [originalChannelDataDict, setOriginalChannelDataDict] = useState(
    generateInitStateChannelDataDictPowerLog(channelConfigList, initStateData)
  );

  // !SECTION

  // SECTION Create or Get option dict for each channel [Not editable]
  const [rawChannelOptionDict, setRawChannelOptionDict] = useState(
    JSON.stringify(
      generateInitStateChannelOptionDictPowerLog(channelConfigList)
    )
  );
  const channelOptionDict: ChannelOptionDictPowerLog =
    JSON.parse(rawChannelOptionDict);
  // !SECTION

  // SECTION Create or get option dict for each "y" [Not editable]
  const [rawYDataOptionDict, setRawYDataOptionDict] = useState(
    JSON.stringify(generateInitStateYDataOptionDictPowerLog(yConfigList))
  );
  const yDataOptionDict: YDataOptionDictPowerLog =
    JSON.parse(rawYDataOptionDict);
  // !SECTION

  // SECTION Create or get option dict for each group [Not editable]
  const [rawGroupOptionDict, setRawGroupOptionDict] = useState(
    JSON.stringify(generateInitStateGroupOptionDictPowerLog(yConfigList))
  );
  const groupOptionDict: GroupOptionDictPowerLog =
    JSON.parse(rawGroupOptionDict);
  // !SECTION
  console.log({
    channelConfigList,
    channelDataDict,
    channelOptionDict,
    groupOptionDict
  });

  // SECTION Create or get util states [Not editable]
  const [showTooltip, setShowTootip] = useState(true);
  const [showRefresh, setRefresh] = useState(true);
  const [showLegends, setShowLegends] = useState(false);
  const [showchannels, setShowChannels] = useState(false);
  const [startTimestampFilter, setStartTimestampFilter] = useState(0);
  const [endTimestampFilter, setEndTimestampFilter] = useState(0);
  const totalVisibleGraphics: number = Object.values(channelOptionDict).filter(
    (channelOption: ChannelOptionPowerLog) => channelOption.isShow
  ).length;
  // !SECTION

  // SECTION Create or get state for the Zoom [Not editable]
  const [rawZoomHistory, setRawZoomHistory] = useState('[]');
  const zoomHistory: ZoomPowerLog[] = JSON.parse(rawZoomHistory);
  // !SECTION

  // SECTION Method Toogle channel [Not editable]
  function toogleChannel(chartKey: string, show: boolean) {
    channelOptionDict[chartKey].isShow = show;
    setRawChannelOptionDict(JSON.stringify(channelOptionDict));
  }
  // !SECTION

  // SECTION Method Toogle "y" data [Not editable]
  function toogleYData(group: string, label: string, checked: boolean) {
    console.log('toogleYData', group, label, checked);
    // console.log('yDataOptionDict', yDataOptionDict);
    // Update in "y" data option
    console.log({ group, label, checked });

    const groupOption: GroupOptionPowerLog = groupOptionDict[group];
    const yKeyList = groupOption.labels[label].yKeyList;
    console.log('yKeyList', yKeyList);
    yKeyList.forEach((yKey: string) => {
      const indexGroupInYData: number =
        yDataOptionDict[yKey].groupList.indexOf(group);
      yDataOptionDict[yKey].isShowByGroup[indexGroupInYData] = checked;
      yDataOptionDict[yKey].isShow =
        !yDataOptionDict[yKey].isShowByGroup.includes(false);
    });
    // Group option
    groupOptionDict[group].labels[label].isShow = checked;
    setRawGroupOptionDict(JSON.stringify(groupOptionDict));
    setRawYDataOptionDict(JSON.stringify(yDataOptionDict));
  }
  // !SECTION

  // get the chart data and store it locally [Not editable]
  async function requestData(payload: GetChartData) {
    dispatch(setLoading({ loading: true }));

    try {
      const updates = await Promise.all(
        channelConfigList.map(async (channelConfig, index) => {
          const response = await ElectripureService.getDataGraph(
            channelConfig.url,
            payload
          );
          if (!response.success) {
            throw new Error(response.error || 'Failed to fetch graph data');
          }
          return {
            key: channelConfig.key,
            data: toData(channelConfig, response.data)
          };
        })
      );

      // Use the setter to update the state only once with all new data

      setOriginalChannelDataDict((prevDict) => {
        const newDict = { ...prevDict };
        updates.forEach((update) => {
          newDict[update.key] = update.data;
        });
        return newDict;
      });
    } catch (error) {
      console.error(error);
      dispatch(showToast({ message: error.message, status: 'error' }));
    } finally {
      dispatch(setLoading({ loading: false }));
    }
  }

  useEffect(() => {
    process_data_per_range();
  }, [originalChannelDataDict]);

  // On filter [Not editable]
  async function onFilter(startSelected?: Date, endSelected?: Date) {
    if (endSelected) setEndDateFilter(endSelected?.getTime());
    if (startSelected) setStartDateFilter(startSelected?.getTime());
  }

  const fetchData = async () => {
    const dateMin: number | null =
      startDateFilter != null ? toUnix(startDateFilter) : null;
    const dateMax: number | null =
      endDateFilter != null ? toUnix(endDateFilter) : null;
    await requestData({
      date_min: dateMin,
      date_max: dateMax,
      device: deviceId,
      points: null
    });
    // Store filter
    setStartTimestampFilter(dateMin ?? 0);
    setEndTimestampFilter(dateMax ?? 0);
    // clear zoom history
    setRawZoomHistory('[]');
    setIsLiveMode(false);
  };

  // Live move
  // const resetGraph = async () => {
  //   setIsLiveMode(true);
  //   const endDate = new Date().getTime();

  //   const startDate = endDate - 86400000;
  //   requestData({
  //     date_min: toUnix(startDate),
  //     date_max: toUnix(endDate),
  //     device: deviceId,
  //     points: null
  //   });
  // };

  async function refresh() {
    const timestampStart: number =
      moment().tz('America/Denver').startOf('day').unix() * 1000;
    const timestampEnd: number =
      moment().tz('America/Denver').endOf('day').unix() * 1000;
    if (endDateFilter > timestampStart && startDateFilter < timestampEnd) {
      onFilter(new Date(startDateFilter), new Date());
    } else {
      onFilter(new Date(startDateFilter), new Date(endDateFilter));
    }
    setTriggerRefresh(true);
  }

  // SECTION Methods for menu
  // Show Only max and min
  const [maxmin, setMaxMin] = useState(true);
  function showOnlyMinMax() {
    toogleYData('Display', 'Maximum', maxmin);
    toogleYData('Display', 'Minimum', maxmin);
    toogleYData('Display', 'Average', !maxmin);
    setMaxMin(!maxmin);
  }
  // !SECTION

  // SECTION Methods for zoom
  // On Zoom [Not editable]
  async function onZoom(x1: any, x2: any, data: ChannelDataPowerLog) {
    const dateMin: number = data.timestamp[x1];
    const dateMax: number = data.timestamp[x2];
    setEndDateFilter(dateMax * 1000);
    setStartDateFilter(dateMin * 1000);
    await requestData({
      date_min: dateMin,
      date_max: dateMax,
      device: deviceId,
      points: null
    });
    // Store zoom history
    setRawZoomHistory(
      JSON.stringify([
        ...zoomHistory,
        {
          date_min: dateMin,
          date_max: dateMax
        }
      ])
    );
  }

  // // Zoom Out [Not editable]
  // async function zoomOut() {
  //   if (zoomHistory.length > 0) {
  //     if (zoomHistory.length == 1) {
  //       resetZoom();
  //     } else if (zoomHistory.length > 1) {
  //       const dataPayload = {
  //         date_min: zoomHistory[zoomHistory.length - 2].date_min,
  //         // 86400 es igual a un dia mas.
  //         date_max: zoomHistory[zoomHistory.length - 2].date_max,
  //         device: deviceId,
  //         points: null
  //       };
  //       setStartDateFilter(dataPayload.date_min * 1000);
  //       setEndDateFilter(dataPayload.date_max * 1000);
  //       await requestData(dataPayload);

  //       let tmpZoom = [...zoomHistory];
  //       var removed = tmpZoom.splice(-1);
  //       setRawZoomHistory(JSON.stringify(tmpZoom));
  //     }
  //   }
  // }

  // // Zoom In [Not editable]
  // async function zoomIn() {
  //   let keys = Object.keys(channelDataDict);
  //   if (keys.length == 0) {
  //     return;
  //   }
  //   const dataX = channelDataDict[keys[0]];
  //   const split = Math.ceil(dataX.timestamp.length / 4 / 2);
  //   const dateMin: number = dataX.timestamp[split - 1];
  //   const dateMax: number = dataX.timestamp[dataX.timestamp.length - split - 1];
  //   setStartDateFilter(dateMin * 1000);
  //   setEndDateFilter(dateMax * 1000);
  //   await requestData({
  //     date_min: dateMin,
  //     date_max: dateMax,
  //     device: deviceId,
  //     points: null
  //   });
  //   setRawZoomHistory(
  //     JSON.stringify([
  //       ...zoomHistory,
  //       {
  //         date_min: dateMin,
  //         date_max: dateMax
  //       }
  //     ])
  //   );
  // }

  async function resetZoom() {
    if (chart.current) chart.current.resetZoom();
  }

  // SECTION Calculare height of the container [Not editable]
  // Get height max [Not editable]
  const containerGraph = useRef(null);
  const [heightControl, setHeightControl] = useState(0);
  const [showCompactGroupControl, setShowCompactGroupControl] = useState(false);
  // useEffect(() => {
  //   setHeightControl((containerGraph.current as any).clientHeight);
  // }, []);
  useEffect(() => {
    setShowCompactGroupControl(totalVisibleGraphics > config.threshold);
  }, [totalVisibleGraphics]);
  useEffect(() => {
    setHeightControl((containerGraph.current as any).clientHeight);
  }, [showCompactGroupControl]);
  // !SECTION

  // SECTION Methods and states for state and position of the menu
  // Get position mouse and show menu [Not editable]
  const [leftMenu, setLeftMenu] = useState(0);
  const [topMenu, setTopMenu] = useState(0);
  const [showMenu, setShowMenu] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  // Open menu
  function handlerRight(e: any) {
    e = e || window.event;
    e.preventDefault();
    let rect = (containerRef.current as any).getBoundingClientRect();
    let x = e.clientX - rect.left;
    let y = e.clientY - rect.top;
    setLeftMenu(x);
    setTopMenu(y);
    setShowMenu(true);
    return false;
  }
  // Close menu
  function handlerClick(e: any) {
    if (showMenu) {
      setShowMenu(false);
    }
  }
  // !SECTION

  useEffect(() => {});
  // SECTION Utils calculates
  const channelKeys = Object.keys(channelOptionDict);
  const totalData: number =
    channelKeys.length > 0
      ? channelDataDict[channelKeys[0]].timestamp.length
      : 0;

  const handleClickOutside = useCallback(
    (e: MouseEvent) => {
      if (!collapsed)
        setStateNavegation(
          sideNavRef && !sideNavRef.current?.contains(e.target as Node)
            ? NAVEGATION_STATE.COMPRESSED
            : NAVEGATION_STATE.OPEN
        );
    },
    [collapsed]
  );

  useEffect(() => {
    if (containerRef.current)
      containerRef.current.addEventListener('mousedown', handleClickOutside);

    return () =>
      containerRef.current?.removeEventListener(
        'mousedown',
        handleClickOutside
      );
  }, [handleClickOutside]);
  // SECTION

  return (
    <div className="relative h-full w-full flex flex-col">
      {/* Controls and filters */}
      <div ref={containerGraph}>
        {/* Controls */}
        <div className="flex justify-start flex-wrap md:flex-nowrap w-[100%]">
          {/* Date control */}
          <div className="min-w-full sm:min-w-[250px]">
            <DateRangeControlCustom2
              defaultStart={new Date(startDateFilter)}
              defaultEnd={new Date(endDateFilter)}
              onChange={onFilter}
            />
          </div>
          <button
            className={`${
              areDatesValid ? 'bg-blue-800' : 'bg-blue-500'
            } ms-2  color-white font-semibold px-2 py-1 rounded-md`}
            onClick={fetchData}
            disabled={!areDatesValid}>
            Set Range
          </button>

          <Space classes="w-[10px] h-[10px]" />

          {/* Options control */}
          <div className="w-full sm:w-auto flex justify-center items-center">
            <ZoomMenu
              records={totalData} // Tatal "y" data
              zooms={zoomHistory.length} // Zoom applied
              onChange={(type: TYPE_EVENTS_ZOOM, checked: boolean) => {
                setIsLiveMode(false);
                if (type == TYPE_EVENTS_ZOOM.RESET) resetZoom();
              }}
            />
            <Space classes="w-[10px]" />
            <EventMenu
              legend={showLegends} // State for buttom legends
              tooltip={showTooltip} // State for buttom tooltip
              refresh={showRefresh} // State for buttom refresh
              onChange={(type: TYPE_EVENTS_EVENT, checked: boolean) => {
                if (type == TYPE_EVENTS_EVENT.LEGENDS) setShowLegends(checked);
                else if (type == TYPE_EVENTS_EVENT.TOOLTIP)
                  setShowTootip(checked);
                else if (type == TYPE_EVENTS_EVENT.REFRESH) {
                  refresh();
                  // fetchData();
                }
              }}
            />
            {showCompactGroupControl ? (
              <Fragment>
                <Space classes="w-[10px]" />
                <GroupMenu
                  channelControl={
                    <ChannelControl
                      channelOptionDict={channelOptionDict}
                      onChange={(
                        channelOption: ChannelOptionPowerLog,
                        checked: boolean
                      ) => {
                        toogleChannel(channelOption.key, checked);
                      }}
                    />
                  }
                  yDataControlList={
                    Object.values(groupOptionDict)
                      .map(
                        (
                          groupOption: GroupOptionPowerLog,
                          index: number
                        ): yDataControl | null => {
                          if (Object.keys(groupOption.labels).length < 1) {
                            return null;
                          }
                          return {
                            name: groupOption.name,
                            element: (
                              <GroupControl
                                key={index}
                                groupOption={groupOption}
                                onChange={(
                                  labelOption: LabelOptionPowerLog,
                                  checked: boolean
                                ) => {
                                  toogleYData(
                                    labelOption.groupName,
                                    labelOption.name,
                                    checked
                                  );
                                }}
                              />
                            )
                          };
                        }
                      )
                      .filter((item) => item != null) as yDataControl[]
                  }
                />
              </Fragment>
            ) : (
              ''
            )}
            {/* <button
              className="ms-2 bg-red-800 color-white font-semibold px-2 py-1 rounded-md"
              onClick={resetGraph}>
              Reset Graph
            </button> */}
          </div>
        </div>

        {/* <Box sx={{ width: 200 }}>
          <h2>Time Range</h2>
          <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
            <p>0</p>
            <Slider
              aria-label="Volume"
              value={timeRange}
              onChange={toggleTimeRange}
              step={1}
              marks
              min={0}
              max={60}
            />
            <p>{timeRange}</p>
          </Stack>
        </Box> */}

        <Space classes="w-[100%] h-[20px]" />

        {/* Filters */}
        {!showCompactGroupControl ? (
          <Fragment>
            <div className="flex justify-between flex-wrap w-[100%]">
              <div>
                <strong>Channels</strong>
                <div className="flex items-center flex-wrap">
                  <ChannelControl
                    channelOptionDict={channelOptionDict}
                    onChange={(
                      channelOption: ChannelOptionPowerLog,
                      checked: boolean
                    ) => {
                      toogleChannel(channelOption.key, checked);
                    }}
                  />
                </div>
              </div>
              {Object.values(groupOptionDict).map(
                (groupOption: GroupOptionPowerLog, index: number) => {
                  return (
                    <div key={index}>
                      <strong>{groupOption.name}</strong>
                      <div className="flex justify-center items-center">
                        <GroupControl
                          groupOption={groupOption}
                          onChange={(
                            labelOption: LabelOptionPowerLog,
                            checked: boolean
                          ) => {
                            toogleYData(
                              labelOption.groupName,
                              labelOption.name,
                              checked
                            );
                          }}
                        />
                      </div>
                    </div>
                  );
                }
              )}
            </div>
            <Space classes="w-[100%] h-[20px]" />
          </Fragment>
        ) : (
          ''
        )}
        <div
          className={`w-full justify-center flex-wrap ${
            showLegends ? 'flex' : 'hidden'
          }`}>
          {Object.values(yConfigList).map((yConfig: YConfig, index: number) => {
            return (
              <div
                key={index}
                className="flex justify-center items-center mx-[10px]">
                <div
                  className="w-[10px] h-[10px] mx-[5px]"
                  style={{ backgroundColor: yConfig.color }}></div>
                <strong>{yConfig.label.toUpperCase()}</strong>
              </div>
            );
          })}
        </div>
      </div>

      {/* Graphs */}
      <div
        ref={containerRef}
        // style={{ height: `calc(100% - ${heightControl}px)` }} // Estilos dinamicos de la altura
        onClick={(e) => {
          handlerClick(e);
        }} // Agregar evento para cerrar menu
        onContextMenu={(e) => {
          handlerRight(e);
        }} // Agregar evento para abrir menu
        className="w-full flex-auto relative">
        <div
          className={`absolute z-50 bg-white p-[5px] shadow shadow-gray-50 border-[1px] ${
            showMenu ? 'block' : 'hidden'
          }`}
          style={{ left: `${leftMenu}px`, top: `${topMenu}px` }}>
          <ul>
            <li
              className="p-[5px] cursor-pointer"
              onClick={() => {
                setShowTootip(!showTooltip);
              }}>
              View Cursor
            </li>
            <li
              className="p-[5px] cursor-pointer"
              onClick={() => {
                setShowLegends(!showLegends);
              }}>
              View Legend
            </li>
            {/* <li className="p-[5px] cursor-pointer" onClick={showOnlyMinMax}>
              View max/min values
            </li> */}
          </ul>
        </div>
        <BuildGraph
          heightEachEl={100 / totalVisibleGraphics}
          channelOptionDict={channelOptionDict}
          channelDataDict={channelDataDict}
          yDataOptionDict={yDataOptionDict}
          axisConfigDict={axisConfigDict}
          showTooltip={showTooltip}
          onZoom={onZoom}
        />
      </div>
    </div>
  );
}

export default PowerLogGraph;
