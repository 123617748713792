const getOrCreateTooltip = (chart) => {
  let tooltipEl = chart.canvas.parentNode.querySelector('.chart-tooltip');

  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.classList.add('chart-tooltip');
    tooltipEl.style.background = 'rgb(255, 255, 255)';
    tooltipEl.style.borderRadius = '8px';
    tooltipEl.style.color = 'rgb(144, 142, 142)';
    tooltipEl.style.opacity = 1;
    tooltipEl.style.pointerEvents = 'none';
    tooltipEl.style.position = 'absolute';
    // tooltipEl.style.transform = 'translate(-50%, 0)';
    tooltipEl.style.transition = 'all .1s ease';
    tooltipEl.style.padding = '10px';
    tooltipEl.style.zIndex = 9999;
    tooltipEl.style.border = '1px solid rgb(0, 0, 0)';

    const table = document.createElement('table');
    table.style.margin = '0px';

    tooltipEl.appendChild(table);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};

export const externalTooltipHandler = (context) => {
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);

  if (!tooltip || tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  // Obtener la fecha/hora del tooltip
  let html = '';
  if (tooltip.title && tooltip.title.length) {
    html += `<div style="font-size:13px; text-align: center"><strong>${tooltip.title[0]}</strong></div>`;
  }

  if (tooltip.dataPoints) {
    tooltip.dataPoints.forEach((dataPoint) => {
      const { dataset } = dataPoint;
      // console.log({ dataPoint });

      const label = dataset.label || '';

      const color = dataset.borderColor || dataset.backgroundColor || '#fff';

      const rawValue = dataPoint.parsed.y;
      const value =
        rawValue !== null && rawValue !== undefined ? rawValue.toFixed(2) : '';

      let valueFormat = `${value}`;
      if (['Imbalance'].includes(dataset.label)) {
        valueFormat = `${value} %`;
      }

      // Cada línea del tooltip
      html += `
        <div style="display: flex; align-items: center; margin-top: 4px;">
          <span 
            style="
              display: inline-block;
              width: 14px; 
              height: 14px; 
              background: ${color};
              border-radius: 22%;
              border: 2px solid #D2D6DE;
              margin-right: 5px;
            ">
          </span>
          <span style="font-size:12px; margin-right:10px; font-weight:500">${label}</span>
          <span style="font-size:12px; margin-left: auto; font-weight:500">${valueFormat}</span>
        </div>
      `;
    });
  }

  tooltipEl.innerHTML = html;

  // 3) Posicionar el tooltip (usando boundingClientRect para mayor precisión)
  const { left, top, width } = chart.canvas.getBoundingClientRect();

  const tooltipWidth = tooltipEl.offsetWidth;
  const tooltipLeft = tooltip.caretX + left;

  if (tooltipLeft + tooltipWidth > width) {
    tooltipEl.style.left = `calc(100% - ${tooltipWidth}px)`;
  } else {
    tooltipEl.style.left = `${tooltipLeft}px`;
  }

  tooltipEl.style.top = `${top + tooltip.caretY - 50}px`;
  tooltipEl.style.opacity = '1';
};
