import env from '@/config/env';
import { ResponseGeneric } from '@/interfaces/base-service';
import { BaseService } from '@/service/base-service';
import { TimeRange } from '../types/date-range';

export class ExportDataService extends BaseService {
  getUrlBase() {
    return env.ELECTRIPURE_ENDPOINT;
  }

  getJwt() {
    return localStorage.getItem('electripureJwt');
  }

  requestGetZip(
    url: string,
    headers: any = {},
    signal?: any
  ): Promise<ResponseGeneric> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            ...headers
          },
          signal: signal
        });
        if (!response.ok) throw new Error('Network response was not ok.');
        const blob = await response.blob();

        resolve({
          data: blob,
          statusCode: 200,
          success: true,
          error: null
        });
      } catch (err) {
        reject({
          data: null,
          statusCode: null,
          success: false,
          error: err
        });
      }
    });
  }

  async getResquestData(path: string, query: TimeRange) {
    try {
      const url = `${this.getUrlBase()}${path}?from=${query.startTime}&to=${query.endTime
        }`;
      const headers = {
        Authorization: `Bearer ${this.getJwt()}`,
        'Content-type': 'application/zip'
      };

      const response = await this.requestGetZip(url, headers);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getDataExportPlot(
    mdpId: string,
    query: TimeRange
  ): Promise<ResponseGeneric> {
    return this.getResquestData(`/v1/meter/${mdpId}/export-plot`, query);
  }
}
