import {
  getLocalStorage,
  removeLocalStorage,
  setLocalStorage
} from '@/functions/local-storage.function';
import { ResponseGeneric } from '@/interfaces/base-service';
import { IAPIResponseError, IRequestPayload } from '@/types/api';
import { LOCAL_STORAGE } from '@/types/enum/local-storage.enum';
import axios, {
  AxiosError,
  AxiosHeaders,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig
} from 'axios';

const baseURL = import.meta.env.VITE_API_URL;
const isNode = import.meta.env.SSR;

let agent;
if (isNode && baseURL.startsWith('https')) {
  const https = await import('https');
  agent = new https.Agent({
    rejectUnauthorized: false
  });
}

const api = axios.create({
  baseURL,
  timeout: 30000,
  httpsAgent: isNode ? agent : undefined
});

// Variables to handle concurrent token refresh
let isRefreshing = false;
let failedQueue: Array<{
  resolve: (token: string) => void;
  reject: (error: any) => void;
}> = [];

const processQueue = (error: any, token: string | null = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else if (token) {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

// Interceptor to add the token in each request
api.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    const token = localStorage.getItem(LOCAL_STORAGE.ACCESS);
    if (token && config.headers) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Interceptor to handle authentication errors (401)
api.interceptors.response.use(
  (response) => response,
  async (error: AxiosError) => {
    const originalRequest = error.config as AxiosRequestConfig & {
      _retry?: boolean;
    };

    // Si obtenemos 401 y no se ha intentado refrescar previamente
    if (
      error.response?.status === 401 &&
      !originalRequest._retry &&
      !originalRequest?.url?.includes('/v1/login')
    ) {
      originalRequest._retry = true;

      // Si ya se está refrescando, se encola la petición
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        }).then((token) => {
          if (originalRequest.headers) {
            originalRequest.headers.Authorization = `Bearer ${token}`;
          }
          return api(originalRequest);
        });
      }

      isRefreshing = true;

      return new Promise(async (resolve, reject) => {
        try {
          const headers = new AxiosHeaders({
            Authorization: `Bearer ${getLocalStorage(LOCAL_STORAGE.REFRESH)}`
          });
          // Call to refresh the token
          const {
            data: { data }
          } = await axios.get(`${baseURL}/v1/refresh-token`, {
            headers
          });

          const { refresh_token, token } = data;
          // Refresh the token in local storage and on the Axios instance
          localStorage.setItem(LOCAL_STORAGE.ACCESS, token);
          setLocalStorage(LOCAL_STORAGE.REFRESH, refresh_token);

          api.defaults.headers.common.Authorization = `Bearer ${token}`;

          processQueue(null, token);

          if (originalRequest.headers) {
            originalRequest.headers.Authorization = `Bearer ${token}`;
          }
          resolve(api(originalRequest));
        } catch (err) {
          const error = err as AxiosError<IAPIResponseError<null>>;
          if (error.status! > 400) {
            removeLocalStorage(LOCAL_STORAGE.ACCESS);
            removeLocalStorage(LOCAL_STORAGE.REFRESH);
          }
          processQueue(err, null);
          reject(err);
        } finally {
          isRefreshing = false;
        }
      });
    }

    return Promise.reject(error);
  }
);

export async function request<T, R>({
  url,
  method,
  body,
  signal,
  params,
  headers
}: IRequestPayload<T>): Promise<ResponseGeneric<R>> {
  let response: R | null = null;
  let error: string | null = null;
  let statusCode: number | undefined = undefined;
  let success: boolean | null = false;
  let isCanceled: boolean | null = false;
  let isError: boolean | null = false;

  // const navigate = useGlobalNavigate();

  try {
    let res: AxiosResponse | null = null;

    const axiosConfig: AxiosRequestConfig = {
      signal,
      params,
      headers
    };

    if (method === 'GET') res = await api.get(url, axiosConfig);
    if (method === 'POST') res = await api.post(url, body, axiosConfig);
    if (method === 'PUT') res = await api.put(url, body, axiosConfig);
    if (method === 'DELETE') res = await api.delete(url, axiosConfig);
    if (method === 'PATCH') res = await api.patch(url, body, axiosConfig);

    response = res?.data;
    success = true;
    statusCode = res?.status;
  } catch (e) {
    const err = e as AxiosError<IAPIResponseError<null>>;
    console.log('url', err.config?.url);

    if (err.config?.url?.includes('/v1/refresh-token')) {
      console.log('No se pudo actualizar el token');
      window.location.href = '/login';
    }

    if (err.code === 'ERR_CANCELED') {
      isCanceled = true;
    } else {
      isError = true;
    }

    const message = err.response?.data?.message as any;
    const errorApi = err.response?.data?.error as any;

    error = message || errorApi || err.message || 'Ocurrio un error en la red';
  }

  return { data: response, error, isCanceled, isError, statusCode, success };
}
