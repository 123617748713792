import {
  getCHPlotData,
  getPowerPlotData,
  getVCPlotData,
  getVHPlotData
} from '@/actions/electripure';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { AVERAGE_LEVEL, TAB_TIME_SERIES_PLOTS } from '../../constants/enum';
import { useTSPContext } from '../../context/timeSeriesPlotsContext';
import { CurrentHarmonicsCanvas } from '../Canvas/CurrentHarmonicsCanvas';
import { PowerCanvas } from '../Canvas/PowerCanvas';
import { VoltageCurrentCanvas } from '../Canvas/VoltageCurrentCanvas';
import { VoltageHarmonicsCanvas } from '../Canvas/VoltageHarmonicsCanvas';

export function BodyTimeSeriesPlots() {
  const {
    currentTab,
    timeRange,
    handleSetVCPlotData,
    handleSetPowerPlotData,
    handleSetVHPlotData,
    handleSetCHPlotData,
    averageLevel
  } = useTSPContext();
  const { meterId } = useParams();
  useEffect(() => {
    const fetchData = async () => {
      if (
        meterId &&
        timeRange?.startTime &&
        timeRange.endTime &&
        handleSetVCPlotData &&
        handleSetPowerPlotData &&
        handleSetVHPlotData &&
        handleSetCHPlotData
      ) {
        const promises = [
          getVCPlotData(
            meterId,
            timeRange?.startTime || '',
            timeRange?.endTime || '',
            averageLevel == AVERAGE_LEVEL.NONE ? '' : (averageLevel as string)
          ),
          getPowerPlotData(
            meterId,
            timeRange?.startTime || '',
            timeRange?.endTime || '',
            averageLevel == AVERAGE_LEVEL.NONE ? '' : (averageLevel as string)
          ),
          getVHPlotData(
            meterId,
            timeRange?.startTime || '',
            timeRange?.endTime || '',
            averageLevel == AVERAGE_LEVEL.NONE
              ? AVERAGE_LEVEL.FIFTEEN_MINUTES
              : (averageLevel as string)
          ),
          getCHPlotData(
            meterId,
            timeRange?.startTime || '',
            timeRange?.endTime || '',
            averageLevel == AVERAGE_LEVEL.NONE
              ? AVERAGE_LEVEL.FIFTEEN_MINUTES
              : (averageLevel as string)
          )
        ];

        const [vcData, powerData, vhData, chData] = await Promise.all(promises);

        handleSetVCPlotData(false, vcData);
        handleSetPowerPlotData(false, powerData);
        handleSetVHPlotData(false, vhData);
        handleSetCHPlotData(false, chData);
      }
    };

    if (
      handleSetVCPlotData &&
      handleSetPowerPlotData &&
      handleSetVHPlotData &&
      handleSetCHPlotData
    ) {
      handleSetVCPlotData(true);
      handleSetPowerPlotData(true);
      handleSetCHPlotData(true);
      handleSetVHPlotData(true);
    }

    fetchData();
  }, [timeRange, averageLevel]);
  return (
    <>
      {currentTab === TAB_TIME_SERIES_PLOTS.VOLTAGE_CURRENT && (
        <VoltageCurrentCanvas />
      )}
      {currentTab === TAB_TIME_SERIES_PLOTS.POWER && <PowerCanvas />}
      {currentTab === TAB_TIME_SERIES_PLOTS.VOLTAGE_HARMONICS && (
        <VoltageHarmonicsCanvas />
      )}
      {currentTab === TAB_TIME_SERIES_PLOTS.CURRENT_HARMONICS && (
        <CurrentHarmonicsCanvas />
      )}
    </>
  );
}
