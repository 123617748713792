import { showToast } from '@/actions/electripure';
import { ProgressActivityIcon } from '@/assets/svg';
import { useTSPContext } from '@/components/TimeSeriesPlots/context/timeSeriesPlotsContext';
import { ExportDataService } from '@/components/TimeSeriesPlots/services/export-data.service';
import { format } from 'date-fns';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

function ExportData() {
  const [isLoading, setIsLoading] = useState(false);
  const { meterId } = useParams();
  const dispatch = useDispatch();

  // console.log({meterId})

  const { timeRange, currentTab } = useTSPContext();
  const [exporDataService] = useState(() => new ExportDataService());

  const handleGenerateExportData = async () => {
    setIsLoading(true);
    try {
      const { data, success } = await exporDataService.getDataExportPlot(
        `${meterId}`,
        {
          startTime: format(
            new Date(timeRange?.startTime || new Date()),
            'yyyy-MM-dd HH:mm:ss'
          ),
          endTime: format(
            new Date(timeRange?.endTime || new Date()),
            'yyyy-MM-dd HH:mm:ss'
          )
        }
      );

      if (success) {
        const url = window.URL.createObjectURL(data);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${meterId}_export_data.zip`;
        document.body.appendChild(a);
        a.click();
        a.remove();

        dispatch(
          showToast({
            message: 'The data was exported.',
            status: 'success'
          }) as any
        );
      } else {
        dispatch(
          showToast({
            message: 'The data was not exported.',
            status: 'error'
          }) as any
        );
      }
    } catch (error) {
      dispatch(
        showToast({
          message: 'The data was not exported.',
          status: 'error'
        }) as any
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <button
      disabled={isLoading}
      onClick={handleGenerateExportData}
      className="box-border text-gray-300-electripure py-3 px-5 rounded-[1px] border-[1px] border-gray-200-electripure ml-2">
      {isLoading ? <ProgressActivityIcon className="animate-spin" /> : 'Export'}
    </button>
  );
}

export default ExportData;
