import { VOLTAGE_CURRENT_CHANNEL } from '@/components/TimeSeriesPlots/constants/enum';
import { DatasetConfig } from '../interface';

export const voltDatasetsConfig: DatasetConfig[] = [
  {
    dataKey: 'volt_a',
    yAxisID: 'y1',
    label: 'Phase A',
    borderColor: '#000000'
  },
  {
    dataKey: 'volt_a_min',
    yAxisID: 'y1',
    label: VOLTAGE_CURRENT_CHANNEL.MIN_A,
    borderColor: '#000000',
    segment: {
      borderDash: [6, 3, 2, 3]
    }
  },
  {
    dataKey: 'volt_a_max',
    yAxisID: 'y1',
    label: VOLTAGE_CURRENT_CHANNEL.MAX_A,
    borderColor: '#000000',
    segment: {
      borderDash: [5, 2]
    }
  },

  {
    dataKey: 'volt_b',
    yAxisID: 'y1',
    label: 'Phase B',
    borderColor: '#DF3C25'
  },
  {
    dataKey: 'volt_b_min',
    yAxisID: 'y1',
    label: VOLTAGE_CURRENT_CHANNEL.MIN_B,
    borderColor: '#DF3C25',
    segment: {
      borderDash: [6, 3, 2, 3]
    }
  },
  {
    dataKey: 'volt_b_max',
    yAxisID: 'y1',
    label: VOLTAGE_CURRENT_CHANNEL.MAX_B,
    borderColor: '#DF3C25',
    segment: {
      borderDash: [5, 2]
    }
  },
  {
    dataKey: 'volt_c',
    yAxisID: 'y1',
    label: 'Phase C',
    borderColor: '#006FF9'
  },
  {
    dataKey: 'volt_c_min',
    yAxisID: 'y1',
    label: VOLTAGE_CURRENT_CHANNEL.MIN_C,
    borderColor: '#006FF9',
    segment: {
      borderDash: [6, 3, 2, 3]
    }
  },
  {
    dataKey: 'volt_c_max',
    yAxisID: 'y1',
    label: VOLTAGE_CURRENT_CHANNEL.MAX_C,
    borderColor: '#006FF9',
    segment: {
      borderDash: [5, 2]
    }
  },
  {
    dataKey: 'volt_n',
    yAxisID: 'y1',
    label: 'Phase N',
    borderColor: '#707070'
  },
  {
    dataKey: 'avg_volt',
    yAxisID: 'y1',
    label: '3 Phase Average',
    title: {
      display: true,
      text: 'Voltage [V]',
      color: '#000'
    },
    segment: {
      borderDash: [5, 2]
    },
    borderColor: '#707070'
  },
  {
    dataKey: 'imbalance_volt',
    yAxisID: 'y2',
    label: 'Imbalance',
    title: {
      display: false
    },
    position: 'right',
    borderColor: '#FC8517',
    segment: {
      borderDash: [5, 2]
    },
    suggestedMax: 20,
    ticks: {
      color: 'orange',
      callback: function (value) {
        return `${value.toFixed(1)}%`;
      },
      stepSize: 4
    },
    grid: {
      display: false
    }
  }
];

export const currDatasetConfig: DatasetConfig[] = [
  {
    dataKey: 'curr_a',
    yAxisID: 'y1',
    borderColor: '#000000',
    label: 'Phase A'
  },
  {
    dataKey: 'curr_a_max',
    yAxisID: 'y1',
    label: VOLTAGE_CURRENT_CHANNEL.MAX_A,
    borderColor: '#000000',
    segment: {
      borderDash: [5, 2]
    }
  },
  {
    dataKey: 'curr_a_min',
    yAxisID: 'y1',
    label: VOLTAGE_CURRENT_CHANNEL.MIN_A,
    borderColor: '#000000',
    segment: {
      borderDash: [6, 3, 2, 3]
    }
  },
  {
    dataKey: 'curr_b',
    yAxisID: 'y1',
    borderColor: '#DF3C25',
    label: 'Phase B'
  },
  {
    dataKey: 'curr_b_max',
    yAxisID: 'y1',
    label: VOLTAGE_CURRENT_CHANNEL.MAX_B,
    borderColor: '#DF3C25',
    segment: {
      borderDash: [5, 2]
    }
  },
  {
    dataKey: 'curr_b_min',
    yAxisID: 'y1',
    label: VOLTAGE_CURRENT_CHANNEL.MIN_B,
    borderColor: '#DF3C25',
    segment: {
      borderDash: [6, 3, 2, 3]
    }
  },
  {
    dataKey: 'curr_c',
    yAxisID: 'y1',
    borderColor: '#006FF9',
    label: 'Phase C'
  },
  {
    dataKey: 'curr_c_max',
    yAxisID: 'y1',
    label: VOLTAGE_CURRENT_CHANNEL.MAX_C,
    borderColor: '#006FF9',
    segment: {
      borderDash: [5, 2]
    }
  },
  {
    dataKey: 'curr_c_min',
    yAxisID: 'y1',
    label: VOLTAGE_CURRENT_CHANNEL.MIN_C,
    borderColor: '#006FF9',
    segment: {
      borderDash: [6, 3, 2, 3]
    }
  },
  {
    dataKey: 'curr_n',
    yAxisID: 'y1',
    borderColor: '#707070',
    label: 'Phase N'
  },
  {
    dataKey: 'avg_curr',
    yAxisID: 'y1',
    label: '3 Phase Average',
    title: {
      display: true,
      text: 'Current [A]',
      color: '#000'
    },
    segment: {
      borderDash: [5, 2]
    },
    borderColor: '#707070'
  },
  {
    dataKey: 'imbalance_curr',
    borderColor: '#FC8517',
    yAxisID: 'y2',
    label: 'Imbalance',
    title: {
      display: false
    },
    position: 'right',
    segment: {
      borderDash: [5, 2]
    },
    suggestedMax: 20,
    ticks: {
      color: 'orange',
      callback: function (value) {
        return `${value.toFixed(1)}%`;
      },
      stepSize: 4
    },
    grid: {
      display: false
    }
  }
];
