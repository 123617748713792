import { LineChart } from '@/common/Chart/LineChart';
import { useTSPContext } from '@/components/TimeSeriesPlots/context/timeSeriesPlotsContext';
import { CustomChartData } from '@/types';
import { useMemo } from 'react';
import { BeatLoader } from 'react-spinners';
import { createChartData } from '../utils';
import { aDatasetsConfig, bDatasetsConfig, cDatasetsConfig } from './config';

export const PowerCanvas = () => {
  const { plotData, currentChannels, clearAllCharts } = useTSPContext();

  const isLoading = useMemo(() => {
    return plotData?.power.isLoading;
  }, [plotData]);

  // const averageChartData: CustomChartData<string, number> = createChartData(
  //   plotData,
  //   averageDatasetsConfig,
  //   'power.data',
  //   currentChannels || []
  // );

  const aChartData: CustomChartData<string, number> = createChartData(
    plotData,
    aDatasetsConfig,
    'power.data',
    currentChannels || []
  );

  const bChartData: CustomChartData<string, number> = createChartData(
    plotData,
    bDatasetsConfig,
    'power.data',
    currentChannels || []
  );

  const cChartData: CustomChartData<string, number> = createChartData(
    plotData,
    cDatasetsConfig,
    'power.data',
    currentChannels || []
  );

  if (clearAllCharts) {
    clearAllCharts();
  }

  return (
    <div className="flex gap-x-4">
      <div className="flex flex-col grow w-full gap-4">
        <div className="relative">
          {isLoading && (
            <BeatLoader className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2" />
          )}
          {/* <LineChart<string, number>
            data={averageChartData}
            visiblePoints={75}
            ticks={25}
            id="average"
            legend
            scaleXDisplay={false}
            maxNumberY2={100}
            enableReset={false}
            className="h-[200px] max-h-[200px]"
            title={{
              display: true,
              text: '3-Phase Average'
            }}
            multipleYAxis
            path="power"
          /> */}
          <LineChart<string, number>
            data={aChartData}
            id="a"
            legend
            visiblePoints={75}
            scaleXDisplay={false}
            maxNumberY2={100}
            ticks={25}
            enableReset={false}
            className="h-[200px] max-h-[200px]"
            title={{
              display: true,
              text: 'Phase A'
            }}
            multipleYAxis
            path="power"
          />
        </div>
        <div>
          <LineChart<string, number>
            data={bChartData}
            id="b"
            visiblePoints={75}
            scaleXDisplay={false}
            maxNumberY2={100}
            ticks={25}
            enableReset={false}
            className="h-[200px] max-h-[200px]"
            title={{
              display: true,
              text: 'Phase B'
            }}
            multipleYAxis
            path="power"
          />
        </div>
        <div>
          <LineChart<string, number>
            data={cChartData}
            id="c"
            visiblePoints={75}
            maxNumberY2={100}
            ticks={25}
            enableReset={false}
            className="h-[200px] max-h-[330px]"
            title={{
              display: true,
              text: 'Phase C'
            }}
            multipleYAxis
            path="power"
          />
        </div>
      </div>
      <div className="flex justify-center items-center">
        <p
          style={{ writingMode: 'vertical-lr' }}
          className="text-[#006FF9] font-semibold">
          Power Factor
        </p>
      </div>
    </div>
  );
};
