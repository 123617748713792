import { VOLTAGE_CURRENT_CHANNEL } from '@/components/TimeSeriesPlots/constants/enum';
import { SwitchChannel } from '@/components/TimeSeriesPlots/types/channel';
import { CustomChartData, Dataset } from '@/types';
import { DatasetConfig } from '../interface';

// create new cloned object
const deepClone = <T>(obj: T): T | undefined =>
  obj ? JSON.parse(JSON.stringify(obj)) : undefined;

export const createChartData = (
  plotData: any,
  datasetsConfig: DatasetConfig[],
  dataPath: string,
  currentChannels: SwitchChannel[]
): CustomChartData<string, number> => {
  const dataObj =
    plotData && dataPath.split('.').reduce((acc, key) => acc?.[key], plotData);

  if (!dataObj) {
    console.warn('Data object not found for path:', dataPath);
    return { labels: [], datasets: [] };
  }

  // assign original dataobj to cloned
  const clonedDataObj = deepClone(dataObj);

  if (!clonedDataObj) {
    console.warn('Failed to clone data object');
    return { labels: [], datasets: [] };
  }

  // filter all inactive channels
  const inactiveChannels = currentChannels
    ?.filter((channel) => !channel.isActive)
    .map((channel) => channel.code);

  // remove data from cloned obj if toggled false
  inactiveChannels?.forEach((channel) => {
    const keys = [];
    switch (dataPath) {
      case 'vc.data':
        if (channel.length === 1) {
          keys.push(
            `curr_${channel.toLowerCase()}`,
            `volt_${channel.toLowerCase()}`
          );
        }
        if (channel === VOLTAGE_CURRENT_CHANNEL.MAX_A) {
          keys.push(`curr_a_max`);
          keys.push(`volt_a_max`);
        }
        if (channel === VOLTAGE_CURRENT_CHANNEL.MAX_B) {
          keys.push(`curr_b_max`);
          keys.push(`volt_b_max`);
        }
        if (channel === VOLTAGE_CURRENT_CHANNEL.MAX_C) {
          keys.push(`curr_c_max`);
          keys.push(`volt_c_max`);
        }
        if (channel === VOLTAGE_CURRENT_CHANNEL.MIN_A) {
          keys.push(`curr_a_min`);
          keys.push(`volt_a_min`);
        }
        if (channel === VOLTAGE_CURRENT_CHANNEL.MIN_B) {
          keys.push(`curr_b_min`);
          keys.push(`volt_b_min`);
        }
        if (channel === VOLTAGE_CURRENT_CHANNEL.MIN_C) {
          keys.push(`curr_c_min`);
          keys.push(`volt_c_min`);
        }
        if (channel === VOLTAGE_CURRENT_CHANNEL.IMBALANCE) {
          keys.push(`imbalance_curr`);
          keys.push(`imbalance_volt`);
        }
        if (channel === VOLTAGE_CURRENT_CHANNEL.PHASE_AVERAGE) {
          keys.push(`avg_curr`);
          keys.push(`avg_volt`);
        }
        break;
      case 'ch.data':
        if (channel === 'THD') {
          keys.push(`thdc_a`);
          keys.push(`thdc_b`);
          keys.push(`thdc_c`);
          keys.push(`thdc_n`);
        }
        if (channel === '3rd') {
          keys.push(`h3c_a`);
          keys.push(`h3c_b`);
          keys.push(`h3c_c`);
          keys.push(`h3c_n`);
        }
        if (channel === '5th') {
          keys.push(`h5c_a`);
          keys.push(`h5c_b`);
          keys.push(`h5c_c`);
          keys.push(`h5c_n`);
        }
        if (channel === '7th') {
          keys.push(`h7c_a`);
          keys.push(`h7c_b`);
          keys.push(`h7c_c`);
          keys.push(`h7c_n`);
        }
        if (channel === '9th') {
          keys.push(`h9c_a`);
          keys.push(`h9c_b`);
          keys.push(`h9c_c`);
          keys.push(`h9c_n`);
        }
        if (channel === '11th') {
          keys.push(`h11c_a`);
          keys.push(`h11c_b`);
          keys.push(`h11c_c`);
          keys.push(`h11c_n`);
        }
        if (channel === '13th') {
          keys.push(`h13c_a`);
          keys.push(`h13c_b`);
          keys.push(`h13c_c`);
          keys.push(`h13c_n`);
        }
        if (channel === '15th') {
          keys.push(`h15c_a`);
          keys.push(`h15c_b`);
          keys.push(`h15c_c`);
          keys.push(`h15c_n`);
        }
        break;
      case 'vh.data':
        if (channel === 'THD') {
          keys.push(`thdv_a`);
          keys.push(`thdv_b`);
          keys.push(`thdv_c`);
        }
        if (channel === '3rd') {
          keys.push(`h3v_a`);
          keys.push(`h3v_b`);
          keys.push(`h3v_c`);
        }
        if (channel === '5th') {
          keys.push(`h5v_a`);
          keys.push(`h5v_b`);
          keys.push(`h5v_c`);
        }
        if (channel === '7th') {
          keys.push(`h7v_a`);
          keys.push(`h7v_b`);
          keys.push(`h7v_c`);
        }
        if (channel === '9th') {
          keys.push(`h9v_a`);
          keys.push(`h9v_b`);
          keys.push(`h9v_c`);
        }
        if (channel === '11th') {
          keys.push(`h11v_a`);
          keys.push(`h11v_b`);
          keys.push(`h11v_c`);
        }
        if (channel === '13th') {
          keys.push(`h13v_a`);
          keys.push(`h13v_b`);
          keys.push(`h13v_c`);
        }
        if (channel === '15th') {
          keys.push(`h15v_a`);
          keys.push(`h15v_b`);
          keys.push(`h15v_c`);
        }
      case 'power.data':
        if (channel === 'PF') {
          keys.push('pf_average');
          keys.push('pf_a');
          keys.push('pf_b');
          keys.push('pf_c');
        }
        if (channel === 'kW') {
          keys.push('kw_average');
          keys.push('kw_a');
          keys.push('kw_b');
          keys.push('kw_c');
        }
        if (channel === 'KVAR') {
          keys.push('kvar_average');
          keys.push('kvar_a');
          keys.push('kvar_b');
          keys.push('kvar_c');
        }
        if (channel === 'kVA') {
          keys.push('kva_average');
          keys.push('kva_a');
          keys.push('kva_b');
          keys.push('kva_c');
        }
    }

    // remove actual data from cloned object
    keys.forEach((key) => {
      if (clonedDataObj[key]) {
        delete clonedDataObj[key];
      }
    });
  });

  return {
    labels: clonedDataObj?.labels || [],
    datasets: datasetsConfig.map(
      (config): Dataset<number> =>
        ({
          data: clonedDataObj?.[config.dataKey] || [],
          yAxisID: config.yAxisID,
          label: config.label,
          borderColor: config.borderColor,
          borderWidth: 1,
          point: { radius: 2 },
          suggestedMax: config.suggestedMax,
          segment: config.segment,
          title: {
            display: config?.title?.display ?? false,
            text: config?.title?.text ?? '',
            color: config?.title?.color ?? ''
          },
          ...(config.position && { position: config.position }),
          ...(config.ticks && { ticks: config.ticks }),
          ...(config.grid && { grid: config.grid })
        } as Dataset<number>)
    )
  };
};
