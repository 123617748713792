import { configureStore } from '@reduxjs/toolkit';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { thunk } from 'redux-thunk';
import AuthContext from './context/AuthContext';
import GraphContext from './context/GraphContext';
import NavContext from './context/NavContext';
import './index.css';
import { electripureReducer } from './reducers/electipure';
import AppRouter from './routers/AppRouter';

const store = configureStore({
  reducer: electripureReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk)
});

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <Provider store={store}>
    <NavContext>
      <AuthContext>
        <GraphContext>
          <AppRouter />
        </GraphContext>
      </AuthContext>
    </NavContext>
  </Provider>
);

{
  /* <FirebaseAppProvider firebaseConfig={environment.FIREBASE_CONFIG}>

</FirebaseAppProvider> */
}
