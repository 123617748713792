import { CheckCircleIcon, ProgressActivityIcon } from '@/assets/svg';
import { initialTabs } from '@/components/TimeSeriesPlots/constants/initialStateChannels.cts';
import { useTSPContext } from '@/components/TimeSeriesPlots/context/timeSeriesPlotsContext';
interface Props {
  className: string;
}

export function TabsTimeSeriesPlots({ className }: Props) {
  const { currentTab, setCurrentTab, plotData } = useTSPContext();

  return (
    <div className="flex  box-border">
      {initialTabs.map((tab, index) => (
        <button
          onClick={() => setCurrentTab?.(tab.code)}
          className={`flex gap-x-2 items-center border-[1px] px-2 py-[2px] first-of-type:rounded-l-[3px] last-of-type:rounded-r-[3px]  ${
            currentTab === tab.code
              ? 'bg-green-300-electripure text-white border-green-300-electripure  '
              : 'bg-white text-gray-400-electripure border-gray-200-electripure'
          }`}
          key={index}>
          <i className="size-5">
            {plotData &&
            plotData[tab.abbrevation as keyof typeof plotData].isLoading ? (
              <ProgressActivityIcon className="animate-spin" />
            ) : (
              <CheckCircleIcon />
            )}
          </i>
          {tab.name}
        </button>
      ))}
    </div>
  );
}
