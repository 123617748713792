import { RefObject, useEffect } from 'react';
const useClickOutside = (
  ref: RefObject<HTMLElement | undefined>,
  callback: ({
    isOutside,
    element
  }: {
    isOutside: boolean;
    element: HTMLElement;
  }) => void
) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as HTMLElement))
        callback({ isOutside: true, element: event.target as HTMLElement });
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  });
};
export default useClickOutside;
