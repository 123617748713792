import { Fragment, useState } from 'react';
import MDPIcon from './item_img.svg';
import { Link, useNavigate, useParams } from 'react-router-dom';

const MDP = ({ site, mdp }: { site: any; mdp: any }) => {
  const meterId: string = mdp.meter_id;
  const mdpId: string = mdp['id'];
  const siteId: string = site['id'];
  const { ciaId } = useParams();
  const [togglesubData, setSubToggleData] = useState(false);
  const navigate = useNavigate();
  return (
    <div className="py-2">
      <hr className="w-full" />
      <div className="w-full flex justify-between">
        <div className="flex ml-4">
          <div className="w-[40px] flex items-center">
            {/* <img className="h-full w-full rounded-md" src={img} alt="image" /> */}
            <MDPIcon />
          </div>
          <div className="break-all grow text-center flex items-center">
            <h3>{mdp.name}</h3>
          </div>
        </div>
        <div
          onClick={() => setSubToggleData((prev) => !togglesubData)}
          className="w-[50px] h-[50px] my-auto border-l-2 cursor-pointer">
          <i
            className={`
                border-solid
                border-black 
                divide-x-[3px] 
                p-[3px] 
                mxlix-4
                ml-4 
                mt-3 
                inline-block 
                border-b-2 
                translate-x-1/2 
                translate-y-1/2 
                border-r-2 ${
                  togglesubData ? 'rotate-[-135deg]' : 'rotate-45'
                }`}></i>
        </div>
      </div>
      {togglesubData && (
        <>
          <div
            className={
              togglesubData
                ? 'text-xs text-left m-1 w-[80%] pl-[40px]'
                : 'hidden'
            }>
            <p>
              <strong>Meter ID: {meterId}</strong>
            </p>
          </div>
          <div className="text-xs text-left m-1 w-[80%] pl-[40px]">
            <p className="font-semibold">Appliance ID: {meterId}</p>
          </div>
          <div className="w-4/5 pl-10 text-black flex flex-col">
            <Link
              to={`company/${ciaId}/meter/${meterId}/system-information`}
              className="font-semibold cursor-pointer">
              System Information
            </Link>
            {/* <Link
              to={`company/${ciaId}/meter/${meterId}/voltagecurrent/graph`}
              className="font-semibold">
              Voltage & Current
            </Link>
            <Link
              to={`company/${ciaId}/meter/${meterId}/harmonic/amp/line1`}
              className="font-semibold">
              Harmonics
            </Link>
            <Link
              to={`company/${ciaId}/meter/${meterId}/power/active`}
              className="font-semibold">
              Power
            </Link> */}
            <Link
              className="font-semibold"
              to={`company/${ciaId}/meter/${meterId}/timeseries-plots`}>
              Timeseries Plots
            </Link>
          </div>
        </>
      )}
    </div>
  );
};

export default MDP;
