import { TAB_TIME_SERIES_PLOTS } from '@/components/TimeSeriesPlots/constants/enum';
import {
  SwitchChannel,
  TypeChannel
} from '@/components/TimeSeriesPlots/types/channel';

interface Props {
  channels?: SwitchChannel[];
  handleToggleChannel: (code: TypeChannel) => void;
  currentTab: TAB_TIME_SERIES_PLOTS | undefined;
}

export function SwitchsChannel({
  channels,
  handleToggleChannel,
  currentTab
}: Props) {
  return (
    <div
      className={`${
        currentTab === TAB_TIME_SERIES_PLOTS.VOLTAGE_CURRENT
          ? 'grid grid-cols-4'
          : 'flex flex-wrap  gap-x-3 justify-center'
      } gap-y-4`}>
      {channels?.map((item) => (
        <label
          className="inline-flex items-center cursor-pointer"
          key={item.code}>
          <input
            type="checkbox"
            className="sr-only peer"
            checked={item.isActive}
          />
          <div
            className={`relative w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 
           dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full
            peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px]
             dark:peer-focus:ring-blue-800
             after:bg-white after:border-gray-300 after:border
           after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 ${item.bgColor}`}
            onClick={() => handleToggleChannel(item.code)}></div>
          <span
            className={`ms-1 text-sm font-semibold ${item.color} dark:text-gray-300`}>
            {item.label}
          </span>
        </label>
      ))}
    </div>
  );
}
