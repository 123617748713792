import { IconRefresh, IconResetZoom } from '@/assets/svg';
import { useTSPContext } from '@/components/TimeSeriesPlots/context/timeSeriesPlotsContext';
import { Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
interface Props {
  optionEvent: string;
}

export function EventMenuTimeSeriesPlots({ optionEvent }: Props) {
  const { resetZoom, timeRange, setTimeRange, plotData } = useTSPContext();
  const [isLoading, setIsLoading] = useState(false);

  const handleRefresh = () => {
    setIsLoading(true);
    if (timeRange && setTimeRange) {
      setTimeRange({
        startTime: timeRange.startTime,
        endTime: timeRange.endTime
      });
    }
    setIsLoading(false);
  };
  useEffect(() => {
    if (
      plotData &&
      Object.values(plotData).every((plot) => plot.isLoading === false)
    ) {
      setIsLoading(false);
    }
  }, [plotData]);

  return (
    <div className="flex ml-2">
      <Tooltip title="Refresh" arrow placement="top">
        <div
          className={`text-blue-500-electripure p-[2px] border border-gray-200-electripure rounded-l-sm ${
            isLoading ? 'animate-spin' : ''
          }`}
          onClick={handleRefresh}>
          <IconRefresh className="size-6" />
        </div>
      </Tooltip>
      <Tooltip title="Reset Zoom" arrow placement="top">
        <div
          className="p-[2px]  border text-gray-300-electripure border-gray-200-electripure rounded-r-sm"
          onClick={() => {
            resetZoom && resetZoom();
          }}>
          <IconResetZoom className="size-6" />
        </div>
      </Tooltip>
    </div>
  );
}
