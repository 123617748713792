import { DatasetConfig } from '../interface';

export const averageDatasetsConfig: DatasetConfig[] = [
  {
    dataKey: 'kw_average',
    yAxisID: 'y1',
    label: 'kW',
    borderColor: '#55BA47'
  },
  {
    dataKey: 'kvar_average',
    yAxisID: 'y1',
    label: 'kVAR',

    borderColor: '#DF3C25'
  },
  {
    dataKey: 'kva_average',
    yAxisID: 'y1',
    label: 'kVA',
    borderColor: '#FC8517',
    title: {
      display: true,
      text: '3-Phase Average',
      color: '#707070'
    }
  },
  {
    dataKey: 'pf_average',
    yAxisID: 'y2',
    label: 'Power Factor',
    borderColor: '#006FF9',
    title: {
      display: false
    },
    suggestedMax: 1,
    position: 'right',
    ticks: {
      color: '#006FF9',
      callback: function (value) {
        return `${value}`;
      }
    },
    grid: {
      display: false
    }
  }
];

export const aDatasetsConfig: DatasetConfig[] = [
  {
    dataKey: 'kw_a',
    yAxisID: 'y1',
    borderColor: '#55BA47',
    label: 'kW'
  },
  {
    dataKey: 'kvar_a',
    yAxisID: 'y1',
    label: 'kVAR',
    borderColor: '#DF3C25'
  },
  {
    dataKey: 'kva_a',
    yAxisID: 'y1',
    label: 'kVA',
    borderColor: '#FC8517',
    title: {
      display: true,
      text: 'Phase A',
      color: '#000'
    }
  },
  {
    dataKey: 'pf_a',
    yAxisID: 'y2',
    borderColor: '#006FF9',
    label: 'Power Factor',
    suggestedMax: 1,
    title: {
      display: false
    },
    position: 'right',
    ticks: {
      color: '#006FF9',
      callback: function (value) {
        return `${value}`;
      }
    },
    grid: {
      display: false
    }
  }
];

export const bDatasetsConfig: DatasetConfig[] = [
  {
    dataKey: 'kw_b',
    yAxisID: 'y1',
    borderColor: '#55BA47',
    label: 'kW'
  },
  {
    dataKey: 'kvar_b',
    yAxisID: 'y1',
    label: 'kVAR',
    borderColor: '#DF3C25'
  },
  {
    dataKey: 'kva_b',
    yAxisID: 'y1',
    label: 'kVA',
    borderColor: '#FC8517',
    title: {
      display: true,
      text: 'Phase B',
      color: '#006FF9'
    }
  },
  {
    dataKey: 'pf_b',
    yAxisID: 'y2',
    borderColor: '#006FF9',
    label: 'Power Factor',
    title: {
      display: false
    },
    suggestedMax: 1,
    position: 'right',
    ticks: {
      color: '#006FF9',
      callback: function (value) {
        return `${value}`;
      }
    },
    grid: {
      display: false
    }
  }
];

export const cDatasetsConfig: DatasetConfig[] = [
  {
    dataKey: 'kw_c',
    yAxisID: 'y1',
    borderColor: '#55BA47',
    label: 'kW'
  },
  {
    dataKey: 'kvar_c',
    yAxisID: 'y1',
    label: 'kVAR',
    borderColor: '#DF3C25'
  },
  {
    dataKey: 'kva_c',
    yAxisID: 'y1',
    borderColor: '#FC8517',
    label: 'kVA',
    title: {
      display: true,
      text: 'Phase c',
      color: '#DF3C25'
    }
  },
  {
    dataKey: 'pf_c',
    yAxisID: 'y2',
    borderColor: '#006FF9',
    label: 'Power Factor',
    title: {
      display: false
    },
    suggestedMax: 1,
    position: 'right',
    ticks: {
      color: '#006FF9',
      callback: function (value) {
        return `${value}`;
      }
    },
    grid: {
      display: false
    }
  }
];
