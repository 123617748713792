import { VOLTAGE_HARMONICS_CHANNEL } from '@/components/TimeSeriesPlots/constants/enum';
import { DatasetConfig } from '../interface';

export const vhaDatasetsConfig: DatasetConfig[] = [
  {
    dataKey: 'thdv_a',
    yAxisID: 'y1',
    position: 'left',
    label: VOLTAGE_HARMONICS_CHANNEL.THD,
    borderColor: '#4C4C4C'
  },
  {
    dataKey: 'h3v_a',
    yAxisID: 'y1',
    label: VOLTAGE_HARMONICS_CHANNEL.$3RD,
    borderColor: '#1F77B4'
  },
  {
    dataKey: 'h5v_a',
    yAxisID: 'y1',
    label: VOLTAGE_HARMONICS_CHANNEL.$5TH,
    borderColor: '#FF7F0E'
  },
  {
    dataKey: 'h7v_a',
    yAxisID: 'y1',
    label: VOLTAGE_HARMONICS_CHANNEL.$7TH,
    borderColor: '#2CA02C'
  },
  {
    dataKey: 'h9v_a',
    yAxisID: 'y1',
    label: VOLTAGE_HARMONICS_CHANNEL.$9TH,
    borderColor: '#D62728'
  },
  {
    dataKey: 'h11v_a',
    yAxisID: 'y1',
    label: VOLTAGE_HARMONICS_CHANNEL.$11TH,
    borderColor: '#9467BD'
  },
  {
    dataKey: 'h13v_a',
    yAxisID: 'y1',
    label: VOLTAGE_HARMONICS_CHANNEL.$13TH,
    borderColor: '#8C564B'
  },

  {
    dataKey: 'h15v_a',
    yAxisID: 'y1',
    label: VOLTAGE_HARMONICS_CHANNEL.$15TH,
    position: 'left',
    title: {
      display: true,
      text: 'Phase A [%]',
      color: '#000'
    },
    borderColor: '#E377C2'
  }
];

export const vhbDatasetsConfig: DatasetConfig[] = [
  {
    dataKey: 'thdv_b',
    yAxisID: 'y1',
    position: 'left',
    borderColor: '#4C4C4C',
    label: VOLTAGE_HARMONICS_CHANNEL.THD
  },
  {
    dataKey: 'h3v_b',
    yAxisID: 'y1',
    borderColor: '#1F77B4',
    label: VOLTAGE_HARMONICS_CHANNEL.$3RD
  },
  {
    dataKey: 'h5v_b',
    yAxisID: 'y1',
    borderColor: '#FF7F0E',
    label: VOLTAGE_HARMONICS_CHANNEL.$5TH
  },
  {
    dataKey: 'h7v_b',
    yAxisID: 'y1',
    borderColor: '#2CA02C',
    label: VOLTAGE_HARMONICS_CHANNEL.$7TH
  },
  {
    dataKey: 'h9v_b',
    yAxisID: 'y1',
    borderColor: '#D62728',
    label: VOLTAGE_HARMONICS_CHANNEL.$9TH
  },
  {
    dataKey: 'h11v_b',
    yAxisID: 'y1',
    borderColor: '#9467BD',
    label: VOLTAGE_HARMONICS_CHANNEL.$11TH
  },
  {
    dataKey: 'h13v_b',
    yAxisID: 'y1',
    borderColor: '#8C564B',
    label: VOLTAGE_HARMONICS_CHANNEL.$13TH
  },

  {
    dataKey: 'h15v_b',
    yAxisID: 'y1',
    position: 'left',
    label: VOLTAGE_HARMONICS_CHANNEL.$15TH,
    title: {
      display: true,
      text: 'Phase B [%]',
      color: '#006FF9'
    },
    borderColor: '#E377C2'
  }
];

export const vhcDatasetsConfig: DatasetConfig[] = [
  {
    dataKey: 'thdv_c',
    yAxisID: 'y1',
    position: 'left',
    borderColor: '#4C4C4C',
    label: VOLTAGE_HARMONICS_CHANNEL.THD
  },
  {
    dataKey: 'h3v_c',
    yAxisID: 'y1',
    borderColor: '#1F77B4',
    label: VOLTAGE_HARMONICS_CHANNEL.$3RD
  },
  {
    dataKey: 'h5v_c',
    yAxisID: 'y1',
    borderColor: '#FF7F0E',
    label: VOLTAGE_HARMONICS_CHANNEL.$5TH
  },
  {
    dataKey: 'h7v_c',
    yAxisID: 'y1',
    borderColor: '#2CA02C',
    label: VOLTAGE_HARMONICS_CHANNEL.$7TH
  },
  {
    dataKey: 'h9v_c',
    yAxisID: 'y1',
    borderColor: '#D62728',
    label: VOLTAGE_HARMONICS_CHANNEL.$9TH
  },
  {
    dataKey: 'h11v_c',
    yAxisID: 'y1',
    borderColor: '#9467BD',
    label: VOLTAGE_HARMONICS_CHANNEL.$11TH
  },
  {
    dataKey: 'h13v_c',
    yAxisID: 'y1',
    borderColor: '#8C564B',
    label: VOLTAGE_HARMONICS_CHANNEL.$13TH
  },

  {
    dataKey: 'h15v_c',
    yAxisID: 'y1',
    position: 'left',
    label: VOLTAGE_HARMONICS_CHANNEL.$15TH,
    title: {
      display: true,
      text: 'Phase C [%]',
      color: '#DF3C25'
    },
    borderColor: '#E377C2'
  }
];

// export const vhnDatasetsConfig: DatasetConfig[] = [
//   {
//     dataKey: 'thdv_n',
//     yAxisID: 'y1',
//     position: 'left',
//     borderColor: '#4C4C4C'
//   },
//   {
//     dataKey: 'h3v_n',
//     yAxisID: 'y1',
//     borderColor: '#1F77B4'
//   },
//   {
//     dataKey: 'h5v_n',
//     yAxisID: 'y1',
//     borderColor: '#FF7F0E'
//   },
//   {
//     dataKey: 'h7v_n',
//     yAxisID: 'y1',
//     borderColor: '#2CA02C'
//   },
//   {
//     dataKey: 'h9v_n',
//     yAxisID: 'y1',
//     borderColor: '#D62728'
//   },
//   {
//     dataKey: 'h11v_n',
//     yAxisID: 'y1',
//     borderColor: '#9467BD'
//   },
//   {
//     dataKey: 'h13v_n',
//     yAxisID: 'y1',
//     borderColor: '#8C564B'
//   },

//   {
//     dataKey: 'h15v_n',
//     yAxisID: 'y1',
//     position: 'left',
//     borderColor: '#E377C2'
//   }
// ];
