import { useTSPContext } from '@/components/TimeSeriesPlots/context/timeSeriesPlotsContext';
import { CustomChartData } from '@/types';
import {
  Chart as ChartJS,
  ChartOptions,
  LinearScale,
  ScaleChartOptions
} from 'chart.js';
import { DeepPartial } from 'chart.js/types/utils';
import zoomPlugin from 'chartjs-plugin-zoom';
import { useEffect, useMemo, useRef } from 'react';
import { ChartLegend } from '../ChartLegend';
import { weekendAreaPlugin } from '../plugins';
import { externalTooltipHandler } from './tooltip';

type LineChartProps<T, D> = {
  enableReset?: boolean;
  data: CustomChartData<T, D>;
  visiblePoints: number;
  ticks: number;
  id?: string;
  legend?: boolean;
  maxNumberY2?: number;
  scaleXDisplay?: boolean;
  multipleYAxis?: boolean;
  highlightWeekends?: boolean;
  title?: {
    [key: string]: any;
  };
  className?: string;
  path?: string;
};

ChartJS.register(LinearScale, zoomPlugin);

export const LineChart = <T, D>({
  data,
  visiblePoints,
  ticks,
  title,
  id,
  legend,
  className,
  maxNumberY2,
  scaleXDisplay = true,
  multipleYAxis = false,
  highlightWeekends = false,
  enableReset = true,
  path
}: LineChartProps<T, D>) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const chartRef = useRef<any>(null);

  const context = (() => {
    try {
      return useTSPContext();
    } catch {
      return null;
    }
  })();

  const totalPoints = data.labels ? data.labels.length - 1 : 0;

  // const min = Math.max(totalPoints - visiblePoints, 0);
  const min = useMemo((): number => {
    return path === 'vc' &&
      context?.voltageCurrentZoomLevel &&
      context?.voltageCurrentZoomLevel.min > 0
      ? context?.voltageCurrentZoomLevel.min
      : path === 'power' &&
        context?.powerZoomLevel &&
        context?.powerZoomLevel.min > 0
      ? context?.powerZoomLevel.min
      : path === 'vh' &&
        context?.voltageHarmonicsZoomLevel &&
        context?.voltageHarmonicsZoomLevel.min > 0
      ? context?.voltageHarmonicsZoomLevel.min
      : path === 'ch' &&
        context?.currentHarmonicsZoomLevel &&
        context?.currentHarmonicsZoomLevel.min > 0
      ? context?.currentHarmonicsZoomLevel.min
      : 0;
  }, [
    path,
    context?.voltageCurrentZoomLevel,
    context?.powerZoomLevel,
    context?.voltageHarmonicsZoomLevel,
    context?.currentHarmonicsZoomLevel
  ]);
  const max = useMemo((): number => {
    return path === 'vc' &&
      context?.voltageCurrentZoomLevel &&
      context?.voltageCurrentZoomLevel.min > 0
      ? context?.voltageCurrentZoomLevel.max
      : path === 'power' &&
        context?.powerZoomLevel &&
        context?.powerZoomLevel.max > 0
      ? context?.powerZoomLevel.max
      : path === 'vh' &&
        context?.voltageHarmonicsZoomLevel &&
        context?.voltageHarmonicsZoomLevel.max > 0
      ? context?.voltageHarmonicsZoomLevel.max
      : path === 'ch' &&
        context?.currentHarmonicsZoomLevel &&
        context?.currentHarmonicsZoomLevel.max > 0
      ? context?.currentHarmonicsZoomLevel.max
      : totalPoints;
  }, [
    path,
    totalPoints,
    context?.voltageCurrentZoomLevel,
    context?.powerZoomLevel,
    context?.voltageHarmonicsZoomLevel,
    context?.currentHarmonicsZoomLevel
  ]);

  const updateZoomValues = (min: number, max: number) => {
    if (path === 'vc' && context && context.setVoltageCurrentZoomLevel) {
      context.setVoltageCurrentZoomLevel({ min, max });
    }
    if (path === 'power' && context && context.setPowerZoomLevel) {
      context.setPowerZoomLevel({ min, max });
    }
    if (path === 'vh' && context && context.setVoltageHarmonicsZoomLevel) {
      context.setVoltageHarmonicsZoomLevel({ min, max });
    }
    if (path === 'ch' && context && context.setCurrentHarmonicsZoomLevel) {
      context.setCurrentHarmonicsZoomLevel({ min, max });
    }
  };

  const getYScale = (data: CustomChartData<T, D>) => {
    const scale: DeepPartial<ScaleChartOptions<'line'>['scales']> = {};
    if (multipleYAxis)
      data.datasets.forEach((d) => {
        if (d.yAxisID)
          scale[d.yAxisID] = {
            type: 'linear',
            position: d.position,
            // display: true,
            // position: d.position,
            // title: d.title,
            min: 0,
            // max: maxNumberY2,
            suggestedMax: d.suggestedMax,
            title: {
              display: d?.title?.display ?? false,
              text: d.title?.text,
              color: d.title?.color,
              font: {
                size: 14,
                weight: 'bold'
              },
              padding: { bottom: 10 }
            },

            ticks: d.ticks,

            grid: d.grid
          };
      });
    else {
      scale.y = {
        beginAtZero: true,
        type: 'linear',
        title: {
          display: title?.display,
          text: title?.text,
          color: 'black'
        }
      };
    }
    return scale;
  };

  const options: ChartOptions = {
    responsive: true,
    interaction: {
      mode: 'index',
      intersect: false
    },
    scales: {
      x: {
        alignToPixels: true,
        min,
        //   ? context?.zoomLevel.min : min,
        // context?.zoomLevel && context?.zoomLevel.min > 0
        //   ? context?.zoomLevel.min
        //   : min,
        max,
        // context?.zoomLevel && context?.zoomLevel.max > 0
        //   ? context?.zoomLevel.max
        //   : max,
        type: 'category',
        ticks: {
          display: scaleXDisplay,
          autoSkip: true,
          maxTicksLimit: ticks,
          minRotation: 75
        },
        grid: {
          display: true,
          color: '#f7f9f9'
        }
      },
      ...getYScale(data)
    },
    plugins: {
      tooltip: {
        enabled: false,
        mode: 'index',
        intersect: false,
        position: 'nearest',
        external: externalTooltipHandler
      },
      legend: {
        display: false
      },
      ...(highlightWeekends ? { weekendAreaPlugin: {} } : {}),
      zoom: {
        pan: {
          enabled: true,
          mode: 'x',
          modifierKey: 'ctrl'
        },
        zoom: {
          wheel: {
            enabled: true,
            speed: 0.4,
            modifierKey: 'shift'
          },
          pinch: {
            enabled: true
          },
          drag: {
            enabled: true
          },
          onZoomComplete(ctx) {
            const { x } = ctx.chart.getZoomedScaleBounds();
            if (x) updateZoomValues(x.min, x.max);
            else updateZoomValues(0, totalPoints);
          },
          mode: 'x'
        }
      }
    }
  };

  const chartData = useMemo(
    () => ({
      ...data,
      datasets: data.datasets.map((d) => {
        const { position, point, ...rest } = d;
        return { ...rest, ...point };
      })
    }),
    [data]
  );

  const legends = useMemo(() => {
    return chartData.datasets.reduce((acc, d) => {
      acc.push({ backgroundColor: d.borderColor, label: d.label || '' });
      return acc;
    }, [] as { backgroundColor: string; label: string }[]);
  }, [chartData]);

  useEffect(() => {
    const ctx = canvasRef.current?.getContext('2d');
    if (ctx) {
      const chart = new ChartJS(ctx, {
        type: 'line',
        data: chartData,
        options,
        plugins: highlightWeekends ? [weekendAreaPlugin] : []
      });

      chartRef.current = chart;

      if (context && context.addChart) {
        context?.addChart(`chart-${id}`, chartRef.current);
      }
      return () => chart.destroy();
    }
  }, [chartData]);

  const resetZoom = () => {
    if (chartRef.current) {
      chartRef.current.resetZoom();
    }
  };

  return (
    <div>
      <div className="mb-3">
        <div className="flex flex-col">
          {enableReset && (
            <button
              className="bg-blue-100-electripure text-white px-3 py-1 rounded-md self-end outline-none"
              onClick={resetZoom}>
              Reset Zoom
            </button>
          )}
          {legend && <ChartLegend legends={legends} />}
        </div>
      </div>
      <canvas className={`${className}`} ref={canvasRef} />
    </div>
  );
};
