import { LineChart } from '@/common/Chart/LineChart';
import { useTSPContext } from '@/components/TimeSeriesPlots/context/timeSeriesPlotsContext';
import { CustomChartData } from '@/types';
import { useMemo } from 'react';
import { BeatLoader } from 'react-spinners';
import { createChartData } from '../utils';
import {
  chaDatasetsConfig,
  chbDatasetsConfig,
  chcDatasetsConfig,
  chnDatasetsConfig
} from './config';

export const CurrentHarmonicsCanvas = () => {
  const { plotData, currentChannels, clearAllCharts } = useTSPContext();

  const isLoading = useMemo(() => {
    return plotData?.vc.isLoading;
  }, [plotData]);

  const chaChartData: CustomChartData<string, number> = createChartData(
    plotData,
    chaDatasetsConfig,
    'ch.data',
    currentChannels || []
  );

  const chbChartData: CustomChartData<string, number> = createChartData(
    plotData,
    chbDatasetsConfig,
    'ch.data',
    currentChannels || []
  );

  const chcChartData: CustomChartData<string, number> = createChartData(
    plotData,
    chcDatasetsConfig,
    'ch.data',
    currentChannels || []
  );

  const chnChartData: CustomChartData<string, number> = createChartData(
    plotData,
    chnDatasetsConfig,
    'ch.data',
    currentChannels || []
  );

  if (clearAllCharts) {
    clearAllCharts();
  }

  return (
    <div className="flex gap-x-4">
      <div className="flex flex-col grow w-full gap-4">
        <div className="relative">
          {isLoading && (
            <BeatLoader className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2" />
          )}
          <LineChart<string, number>
            data={chaChartData}
            visiblePoints={75}
            ticks={25}
            id="cha"
            legend
            scaleXDisplay={false}
            enableReset={false}
            className="h-[200px] max-h-[200px]"
            title={{
              display: true,
              text: 'Phase A [%]'
            }}
            multipleYAxis
            path="ch"
          />
        </div>
        <div>
          <LineChart<string, number>
            data={chbChartData}
            id="chb"
            visiblePoints={75}
            ticks={25}
            scaleXDisplay={false}
            enableReset={false}
            className="h-[200px] max-h-[200px]"
            title={{
              display: true,
              text: 'Phase B [%]'
            }}
            multipleYAxis
            path="ch"
          />
        </div>
        <div>
          <LineChart<string, number>
            data={chcChartData}
            id="chc"
            visiblePoints={75}
            ticks={25}
            scaleXDisplay={false}
            enableReset={false}
            className="h-[200px] max-h-[200px]"
            title={{
              display: true,
              text: 'Phase C [%]'
            }}
            multipleYAxis
            path="ch"
          />
        </div>
        <div>
          <LineChart<string, number>
            data={chnChartData}
            id="chn"
            visiblePoints={75}
            scaleXDisplay={true}
            ticks={25}
            enableReset={false}
            className="h-[200px] max-h-[330px]"
            title={{
              display: true,
              text: 'Phase N [%]'
            }}
            multipleYAxis
            path="ch"
          />
        </div>
      </div>
    </div>
  );
};
