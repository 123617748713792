export function setLocalStorage(key: string, value: any) {
  localStorage.setItem(key, JSON.stringify(value));
  window.dispatchEvent(
    new StorageEvent('storage', {
      key,
      newValue: JSON.stringify(value),
      storageArea: localStorage
    })
  );
}

export function getLocalStorage(key: string) {
  const storedValue = localStorage.getItem(key);
  return storedValue ? JSON.parse(storedValue) : null;
}

export function removeLocalStorage(key: string) {
  localStorage.removeItem(key);
  window.dispatchEvent(
    new StorageEvent('storage', {
      key,
      newValue: null,
      storageArea: localStorage
    })
  );
}
