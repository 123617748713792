import { LineChart } from '@/common/Chart/LineChart';
import { useTSPContext } from '@/components/TimeSeriesPlots/context/timeSeriesPlotsContext';
import { CustomChartData } from '@/types';
import { useMemo } from 'react';
import { BeatLoader } from 'react-spinners';
import { createChartData } from '../utils';
import { currDatasetConfig, voltDatasetsConfig } from './config';

export const VoltageCurrentCanvas = () => {
  const { plotData, currentChannels, clearAllCharts } = useTSPContext();

  const isLoading = useMemo(() => {
    return plotData?.vc.isLoading;
  }, [plotData]);

  const voltChartData: CustomChartData<string, number> = createChartData(
    plotData,
    voltDatasetsConfig,
    'vc.data',
    currentChannels || []
  );

  const currChartData: CustomChartData<string, number> = createChartData(
    plotData,
    currDatasetConfig,
    'vc.data',
    currentChannels || []
  );

  if (clearAllCharts) {
    clearAllCharts();
  }

  return (
    <div className="flex gap-x-4">
      <div className="flex flex-col grow w-full gap-4">
        <div className="relative">
          {isLoading && (
            <BeatLoader className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2" />
          )}
          <LineChart<string, number>
            data={voltChartData}
            visiblePoints={75}
            ticks={25}
            id="volt"
            legend
            scaleXDisplay={false}
            enableReset={false}
            className="h-[250px] max-h-[250px]"
            title={{
              display: true,
              text: 'Voltage [V]'
            }}
            multipleYAxis
            path="vc"
          />
        </div>
        <div>
          <LineChart<string, number>
            data={currChartData}
            id="curr"
            visiblePoints={75}
            ticks={25}
            enableReset={false}
            className="h-[250px] max-h-[360px]"
            title={{
              display: true,
              text: 'Current [A]'
            }}
            multipleYAxis
            path="vc"
          />
        </div>
      </div>
      <div className="flex justify-center items-center">
        <p
          style={{ writingMode: 'vertical-lr' }}
          className="text-orange-500 font-semibold">
          3 Phase Maximum Imbalance [%]
        </p>
      </div>
    </div>
  );
};
