export enum AVERAGE_LEVEL {
  NONE = 'none',
  FIFTEEN_MINUTES = '15m',
  THIRTY_MINUTES = '30m',
  SIXTY_MINUTES = '60m',
  SIX_HOURS = '6h',
  TWELVE_HOURS = '12h',
  TWENTY_FOUR_HOURS = '24h'
}

/**** CHANNELS ****/
export enum VOLTAGE_CURRENT_CHANNEL {
  A = 'A',
  MAX_A = 'A max',
  MIN_A = 'A min',
  B = 'B',
  MAX_B = 'B max',
  MIN_B = 'B min',
  C = 'C',
  MAX_C = 'C max',
  MIN_C = 'C min',
  N = 'N',
  PHASE_AVERAGE = 'Average',
  IMBALANCE = 'Imbalance'
}

export enum POWER_CHANNEL {
  PF = 'PF',
  KW = 'kW',
  KVAR = 'KVAR',
  KVA = 'kVA'
}

export enum VOLTAGE_HARMONICS_CHANNEL {
  THD = 'THD',
  $3RD = '3rd',
  $5TH = '5th',
  $7TH = '7th',
  $9TH = '9th',
  $11TH = '11th',
  $13TH = '13th',
  $15TH = '15th'
}

export enum CURRENT_HARMONICS_CHANNEL {
  THD = 'THD',
  $3RD = '3rd',
  $5TH = '5th',
  $7TH = '7th',
  $9TH = '9th',
  $11TH = '11th',
  $13TH = '13th',
  $15TH = '15th'
}

export enum TAB_TIME_SERIES_PLOTS {
  VOLTAGE_CURRENT = 'VOLTAGE_CURRENT',
  POWER = 'POWER',
  VOLTAGE_HARMONICS = 'VOLTAGE_HARMONICS',
  CURRENT_HARMONICS = 'CURRENT_HARMONICS'
}
