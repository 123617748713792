import { CURRENT_HARMONICS_CHANNEL } from '@/components/TimeSeriesPlots/constants/enum';
import { DatasetConfig } from '../interface';

export const chaDatasetsConfig: DatasetConfig[] = [
  {
    dataKey: 'thdc_a',
    yAxisID: 'y1',
    position: 'left',
    label: CURRENT_HARMONICS_CHANNEL.THD,
    borderColor: '#4C4C4C'
  },
  {
    dataKey: 'h3c_a',
    yAxisID: 'y1',
    label: CURRENT_HARMONICS_CHANNEL.$3RD,
    borderColor: '#1F77B4'
  },
  {
    dataKey: 'h5c_a',
    yAxisID: 'y1',
    label: CURRENT_HARMONICS_CHANNEL.$5TH,
    borderColor: '#FF7F0E'
  },
  {
    dataKey: 'h7c_a',
    yAxisID: 'y1',
    label: CURRENT_HARMONICS_CHANNEL.$7TH,
    borderColor: '#2CA02C'
  },
  {
    dataKey: 'h9c_a',
    yAxisID: 'y1',
    label: CURRENT_HARMONICS_CHANNEL.$9TH,
    borderColor: '#D62728'
  },
  {
    dataKey: 'h11c_a',
    yAxisID: 'y1',
    label: CURRENT_HARMONICS_CHANNEL.$11TH,
    borderColor: '#9467BD'
  },
  {
    dataKey: 'h13c_a',
    yAxisID: 'y1',
    label: CURRENT_HARMONICS_CHANNEL.$13TH,
    borderColor: '#8C564B'
  },

  {
    dataKey: 'h15c_a',
    yAxisID: 'y1',
    label: CURRENT_HARMONICS_CHANNEL.$15TH,
    position: 'left',
    title: {
      display: true,
      text: 'Phase A [%]',
      color: '#000'
    },
    borderColor: '#E377C2'
  }
];

export const chbDatasetsConfig: DatasetConfig[] = [
  {
    dataKey: 'thdc_b',
    yAxisID: 'y1',
    position: 'left',
    borderColor: '#4C4C4C',
    label: CURRENT_HARMONICS_CHANNEL.THD
  },
  {
    dataKey: 'h3c_b',
    yAxisID: 'y1',
    borderColor: '#1F77B4',
    label: CURRENT_HARMONICS_CHANNEL.$3RD
  },
  {
    dataKey: 'h5c_b',
    yAxisID: 'y1',
    borderColor: '#FF7F0E',
    label: CURRENT_HARMONICS_CHANNEL.$5TH
  },
  {
    dataKey: 'h7c_b',
    yAxisID: 'y1',
    borderColor: '#2CA02C',
    label: CURRENT_HARMONICS_CHANNEL.$7TH
  },
  {
    dataKey: 'h9c_b',
    yAxisID: 'y1',
    borderColor: '#D62728',
    label: CURRENT_HARMONICS_CHANNEL.$9TH
  },
  {
    dataKey: 'h11c_b',
    yAxisID: 'y1',
    borderColor: '#9467BD',
    label: CURRENT_HARMONICS_CHANNEL.$11TH
  },
  {
    dataKey: 'h13c_b',
    yAxisID: 'y1',
    borderColor: '#8C564B',
    label: CURRENT_HARMONICS_CHANNEL.$13TH
  },

  {
    dataKey: 'h15c_b',
    yAxisID: 'y1',
    position: 'left',
    label: CURRENT_HARMONICS_CHANNEL.$15TH,
    title: {
      display: true,
      text: 'Phase B [%]',
      color: '#006FF9'
    },
    borderColor: '#E377C2'
  }
];

export const chcDatasetsConfig: DatasetConfig[] = [
  {
    dataKey: 'thdc_c',
    yAxisID: 'y1',
    position: 'left',
    borderColor: '#4C4C4C',
    label: CURRENT_HARMONICS_CHANNEL.THD
  },
  {
    dataKey: 'h3c_c',
    yAxisID: 'y1',
    borderColor: '#1F77B4',
    label: CURRENT_HARMONICS_CHANNEL.$3RD
  },
  {
    dataKey: 'h5c_c',
    yAxisID: 'y1',
    borderColor: '#FF7F0E',
    label: CURRENT_HARMONICS_CHANNEL.$5TH
  },
  {
    dataKey: 'h7c_c',
    yAxisID: 'y1',
    borderColor: '#2CA02C',
    label: CURRENT_HARMONICS_CHANNEL.$7TH
  },
  {
    dataKey: 'h9c_c',
    yAxisID: 'y1',
    borderColor: '#D62728',
    label: CURRENT_HARMONICS_CHANNEL.$9TH
  },
  {
    dataKey: 'h11c_c',
    yAxisID: 'y1',
    borderColor: '#9467BD',
    label: CURRENT_HARMONICS_CHANNEL.$11TH
  },
  {
    dataKey: 'h13c_c',
    yAxisID: 'y1',
    borderColor: '#8C564B',
    label: CURRENT_HARMONICS_CHANNEL.$13TH
  },

  {
    dataKey: 'h15c_c',
    yAxisID: 'y1',
    position: 'left',
    label: CURRENT_HARMONICS_CHANNEL.$15TH,
    title: {
      display: true,
      text: 'Phase C [%]',
      color: '#DF3C25'
    },
    borderColor: '#E377C2'
  }
];

export const chnDatasetsConfig: DatasetConfig[] = [
  {
    dataKey: 'thdc_n',
    yAxisID: 'y1',
    position: 'left',
    borderColor: '#4C4C4C',
    label: CURRENT_HARMONICS_CHANNEL.THD
  },
  {
    dataKey: 'h3c_n',
    yAxisID: 'y1',
    borderColor: '#1F77B4',
    label: CURRENT_HARMONICS_CHANNEL.$3RD
  },
  {
    dataKey: 'h5c_n',
    yAxisID: 'y1',
    borderColor: '#FF7F0E',
    label: CURRENT_HARMONICS_CHANNEL.$5TH
  },
  {
    dataKey: 'h7c_n',
    yAxisID: 'y1',
    borderColor: '#2CA02C',
    label: CURRENT_HARMONICS_CHANNEL.$7TH
  },
  {
    dataKey: 'h9c_n',
    yAxisID: 'y1',
    borderColor: '#D62728',
    label: CURRENT_HARMONICS_CHANNEL.$9TH
  },
  {
    dataKey: 'h11c_n',
    yAxisID: 'y1',
    borderColor: '#9467BD',
    label: CURRENT_HARMONICS_CHANNEL.$11TH
  },
  {
    dataKey: 'h13c_n',
    yAxisID: 'y1',
    borderColor: '#8C564B',
    label: CURRENT_HARMONICS_CHANNEL.$13TH
  },

  {
    dataKey: 'h15c_n',
    yAxisID: 'y1',
    position: 'left',
    label: CURRENT_HARMONICS_CHANNEL.$15TH,
    title: {
      display: true,
      text: 'Phase N [%]',
      color: '#707070'
    },
    borderColor: '#707070'
  }
];
