import { startOfDay } from 'date-fns';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { AVERAGE_LEVEL, TAB_TIME_SERIES_PLOTS } from '../constants/enum';
import { initialStateChannels } from '../constants/initialStateChannels.cts';
import { SwitchChannel, TypeChannel } from '../types/channel';
import { TimeRange } from '../types/date-range';

interface ContextProps {
  currentTab: TAB_TIME_SERIES_PLOTS;
  setCurrentTab: (tab: TAB_TIME_SERIES_PLOTS) => void;
  timeRange: TimeRange;
  setTimeRange: (timeRange: TimeRange) => void;
  currentChannels: SwitchChannel[];
  handleToggleChannel: (code: TypeChannel) => void;
  averageLevel: AVERAGE_LEVEL;
  setAverageLevel: (averageLevel: AVERAGE_LEVEL) => void;
  handleSetVCPlotData: (isLoading?: boolean, data?: VCPlotData) => void;
  handleSetPowerPlotData: (isLoading?: boolean, data?: PowerPlotData) => void;
  handleSetVHPlotData: (isLoading?: boolean, data?: VHPlotData) => void;
  handleSetCHPlotData: (isLoading?: boolean, data?: CHPlotData) => void;
  addChart: (id: string, chartRef: any) => void;
  clearAllCharts: () => void;
  currentHarmonicsZoomLevel: {
    min: number;
    max: number;
  };
  setCurrentHarmonicsZoomLevel: (zoom: { min: number; max: number }) => void;
  powerZoomLevel: {
    min: number;
    max: number;
  };
  setPowerZoomLevel: (zoom: { min: number; max: number }) => void;
  voltageCurrentZoomLevel: {
    min: number;
    max: number;
  };
  setVoltageCurrentZoomLevel: (zoom: { min: number; max: number }) => void;
  voltageHarmonicsZoomLevel: {
    min: number;
    max: number;
  };
  setVoltageHarmonicsZoomLevel: (zoom: { min: number; max: number }) => void;
  resetZoom: () => void;
  plotData: {
    vc: {
      isLoading: boolean;
      data?: VCPlotData;
    };
    power: {
      isLoading: boolean;
      data?: PowerPlotData;
    };
    vh: {
      isLoading: boolean;
      data?: VHPlotData;
    };
    ch: {
      isLoading: boolean;
      data?: CHPlotData;
    };
  };
  charts: {
    [id: string]: any;
  };
}

const TSPContext = createContext<Partial<ContextProps>>({});

interface TSPContextProviderProps {
  children: ReactNode;
}

export const TSPContextProvider: React.FC<TSPContextProviderProps> = ({
  children
}) => {
  const [currentTab, setCurrentTab] = useState<TAB_TIME_SERIES_PLOTS>(
    TAB_TIME_SERIES_PLOTS.VOLTAGE_CURRENT
  );
  const [timeRange, setTimeRange] = useState<TimeRange>({
    startTime: startOfDay(new Date()).toUTCString(),
    endTime: new Date().toUTCString()
  });
  const [currentChannels, setCurrentChannels] = useState<SwitchChannel[]>(
    initialStateChannels[TAB_TIME_SERIES_PLOTS.VOLTAGE_CURRENT]
  );

  const [vcPlotData, setVCPlotsData] = useState<{
    isLoading: boolean;
    data?: VCPlotData;
  }>({
    isLoading: true
  });

  const [powerPlotData, setPowerPlotData] = useState<{
    isLoading: boolean;
    data?: PowerPlotData;
  }>({
    isLoading: true
  });

  const [vhPlotData, setVHPlotData] = useState<{
    isLoading: boolean;
    data?: VHPlotData;
  }>({ isLoading: true });

  const [chPlotData, setCHPlotData] = useState<{
    isLoading: boolean;
    data?: CHPlotData;
  }>({
    isLoading: true
  });

  const [averageLevel, setAverageLevel] = useState<AVERAGE_LEVEL>(
    AVERAGE_LEVEL.NONE
  );

  const [currentHarmonicsZoomLevel, setCurrentHarmonicsZoomLevel] = useState({
    min: 0,
    max: 0
  });
  const [powerZoomLevel, setPowerZoomLevel] = useState({
    min: 0,
    max: 0
  });
  const [voltageCurrentZoomLevel, setVoltageCurrentZoomLevel] = useState({
    min: 0,
    max: 0
  });
  const [voltageHarmonicsZoomLevel, setVoltageHarmonicsZoomLevel] = useState({
    min: 0,
    max: 0
  });
  const charts = useRef<Map<string, any>>(new Map());

  const addChart = (id: string, chartRef: any) => {
    charts.current.set(id, chartRef);
  };

  const clearAllCharts = () => {
    charts.current.clear();
  };

  const handleToggleChannel = useCallback(
    (code: TypeChannel) => {
      const newChannels = currentChannels.map((channel) => ({
        ...channel,
        isActive: code === channel.code ? !channel.isActive : channel.isActive
      }));

      setCurrentChannels(newChannels);
    },
    [currentChannels]
  );

  const handleSetVCPlotData = (
    isLoading: boolean = true,
    data?: VCPlotData
  ) => {
    setVCPlotsData({
      isLoading,
      data
    });
  };

  const handleSetPowerPlotData = (
    isLoading: boolean = true,
    data?: PowerPlotData
  ) => {
    setPowerPlotData({
      isLoading,
      data
    });
  };

  const handleSetVHPlotData = (
    isLoading: boolean = true,
    data?: VHPlotData
  ) => {
    setVHPlotData({
      isLoading,
      data
    });
  };

  const handleSetCHPlotData = (
    isLoading: boolean = true,
    data?: CHPlotData
  ) => {
    setCHPlotData({
      isLoading,
      data
    });
  };

  const resetZoom = () => {
    charts.current.forEach((chart) => {
      chart.resetZoom();
    });

    setCurrentHarmonicsZoomLevel({
      min: 0,
      max: 0
    });
    setPowerZoomLevel({
      min: 0,
      max: 0
    });
    setVoltageCurrentZoomLevel({
      min: 0,
      max: 0
    });
    setVoltageHarmonicsZoomLevel({
      min: 0,
      max: 0
    });
  };

  useEffect(() => {
    setCurrentChannels(initialStateChannels[currentTab]);
  }, [currentTab]);

  useEffect(() => {
    resetZoom();
  }, [averageLevel]);

  const plotData = useMemo(
    () => ({
      vc: vcPlotData,
      power: powerPlotData,
      vh: vhPlotData,
      ch: chPlotData
    }),
    [vcPlotData, powerPlotData, vhPlotData, chPlotData]
  );

  return (
    <TSPContext.Provider
      value={{
        currentTab,
        setCurrentTab,
        timeRange,
        setTimeRange,
        currentChannels,
        averageLevel,
        setAverageLevel,
        handleToggleChannel,
        handleSetVCPlotData,
        handleSetPowerPlotData,
        handleSetVHPlotData,
        handleSetCHPlotData,
        addChart,
        charts,
        clearAllCharts,
        resetZoom,
        currentHarmonicsZoomLevel,
        setCurrentHarmonicsZoomLevel,
        powerZoomLevel,
        setPowerZoomLevel,
        voltageCurrentZoomLevel,
        setVoltageCurrentZoomLevel,
        voltageHarmonicsZoomLevel,
        setVoltageHarmonicsZoomLevel,
        plotData
      }}>
      {children}
    </TSPContext.Provider>
  );
};

export const useTSPContext = () => useContext(TSPContext);
