import { averageModeOptions } from '@/components/TimeSeriesPlots/constants/averageLevel.cts';
import { AVERAGE_LEVEL } from '@/components/TimeSeriesPlots/constants/enum';
import { useTSPContext } from '@/components/TimeSeriesPlots/context/timeSeriesPlotsContext';

export function AverageLevelControl() {
  const { averageLevel, setAverageLevel } = useTSPContext();

  const handleSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setAverageLevel?.(e.target.value as unknown as AVERAGE_LEVEL);
  };

  return (
    <div className="flex gap-x-1 items-center mt-4">
      <span className="text-gray-300-electripure w-44">Average Level:</span>
      <div className="relative w-full">
        <select
          id="averageLevel"
          value={averageLevel?.toString()}
          onChange={handleSelect}
          className="bg-gray-50 border border-gray-300 text-gray-300-electripure text-sm rounded-sm font-semibold
           focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600
            dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
          {averageModeOptions.map((option: any, index: number) => (
            <option value={option.value} selected key={index}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}
