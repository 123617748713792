import { LOCAL_STORAGE } from '@/config/enum';
import { CodeValidateResponse } from '@/types/responses/auth/code-validate.response';
import { LoginResponse } from '@/types/responses/auth/login.response';
import moment from 'moment';
import { toast } from 'react-toastify';
import { ResponseGeneric } from '../interfaces/base-service';
import {
  AddContactRequest,
  AuthorizationCodeRequest,
  AuthorizationCodeValidateRequest,
  CreateUserRequest,
  ForgotPasswordRequest,
  GetAmpsDataRequest,
  GetChartData,
  GetCompaniesByUserRequest,
  GetPowerDataRequest,
  GetVoltsDataRequest,
  ListFilesCompanyRequest,
  LoginRequest,
  ResendEmailRequest,
  SetFCMTokenRequest,
  ToogleCompanyStateRequest,
  ToogleUserStateRequest,
  UpdatePasswordRequest,
  UpdateUserRequest,
  UploadFileRequest,
  UploadImageRequest,
  ValidateTokenRequest,
  ValidateUpdateUserRequest
} from '../interfaces/electripure-service';
import { request } from './axios.config';
import { BaseService } from './base-service';

export default class ElectripureService extends BaseService {
  static async login(payload: LoginRequest): Promise<ResponseGeneric<any>> {
    const url = `/v1/login`;
    console.log(url);
    const response = await request<any, any>({
      method: 'POST',
      url,
      body: payload
    });
    return response;
  }

  static async validateToken(
    payload: ValidateTokenRequest
  ): Promise<ResponseGeneric<any>> {
    const url = `/validate_token?${this.jsonToQueryParams(payload)}`;
    const response = await request<any, LoginResponse>({
      method: 'POST',
      url,
      body: payload
    });
    return response;
  }

  static async authorizationCode(
    payload: AuthorizationCodeRequest
  ): Promise<ResponseGeneric<any>> {
    const url = `/autherization_code?${this.jsonToQueryParams(payload)}`;
    const response = await request<any, any>({
      method: 'POST',
      url,
      body: payload
    });
    return response;
  }

  static async authorizationCodeValidate(
    payload: AuthorizationCodeValidateRequest
  ): Promise<ResponseGeneric<CodeValidateResponse>> {
    const url = `/v1/autherization_code_validate?${this.jsonToQueryParams(
      payload
    )}`;
    const response = await request<any, CodeValidateResponse>({
      url,
      method: 'POST',
      body: payload
    });
    return response;
  }

  static async updateUser(
    payload: UpdateUserRequest
  ): Promise<ResponseGeneric<any>> {
    const url = `/update_user?${this.jsonToQueryParams(payload)}`;
    const response = await request<any, any>({
      method: 'POST',
      url,
      body: payload
    });
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async validateUpdateUser(
    payload: ValidateUpdateUserRequest
  ): Promise<ResponseGeneric<any>> {
    const url = `/validate_update_user?${this.jsonToQueryParams(payload)}`;
    const response = await request<any, any>({
      method: 'POST',
      url,
      body: payload
    });
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async addContact(
    payload: AddContactRequest
  ): Promise<ResponseGeneric<any>> {
    const url = `/add_contact?${this.jsonToQueryParams(payload)}`;
    const response = await request<any, any>({
      method: 'POST',
      url,
      body: payload
    });
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async createCompany(payload: any): Promise<ResponseGeneric<any>> {
    const url = `/create_company`;
    const response = await request<any, any>({
      method: 'POST',
      url,
      body: payload
    });
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async archiveCompany(payload: any): Promise<ResponseGeneric<any>> {
    const url = `/archive_company`;
    const response = await request<any, any>({
      method: 'POST',
      url,
      body: payload
    });
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async getUsers(): Promise<ResponseGeneric<any>> {
    const url = `/get_users`;

    const response = await request<any, any>({
      method: 'POST',
      url
    });
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async getOverview(): Promise<any> {
    const url = `/overview`;
    const response = await request<any, any>({ url, method: 'GET' });

    if (response.isError) throw new Error(response.error!);
    return response.data;
  }

  static async resendEmail(
    payload: ResendEmailRequest
  ): Promise<ResponseGeneric<any>> {
    const url = `/resend_email?${this.jsonToQueryParams(payload)}`;

    const response = await request<any, any>({
      method: 'POST',
      url,
      body: payload
    });
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }
  static async toogleUserState(
    payload: ToogleUserStateRequest
  ): Promise<ResponseGeneric<any>> {
    const url = `/change_status_user`;

    const response = await request<any, any>({
      method: 'POST',
      url,
      body: payload
    });
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response.data;
  }

  static async toogleCompanyState(
    payload: ToogleCompanyStateRequest
  ): Promise<ResponseGeneric<any>> {
    const url = `/change_status_company`;
    const response = await request<any, any>({
      method: 'POST',
      url,
      body: payload
    });
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async forgotPassword(
    payload: ForgotPasswordRequest
  ): Promise<ResponseGeneric<any>> {
    const url = `/forgot_password?${this.jsonToQueryParams(payload)}`;
    const response = await request<any, any>({
      method: 'POST',
      url,
      body: payload
    });
    return response;
  }

  static async updatePassword(
    payload: UpdatePasswordRequest
  ): Promise<ResponseGeneric<any>> {
    const url = `/update_pwd?${this.jsonToQueryParams(payload)}`;
    const response = await request<any, any>({
      method: 'POST',
      url,
      body: payload
    });
    return response;
  }

  static async createUser(
    payload: CreateUserRequest
  ): Promise<ResponseGeneric<any>> {
    const url = `/create_user?${this.jsonToQueryParams(payload)}`;

    // TODO this is a temporal fix related to the new roles/permissions refactored backend
    let fixed_payload = {
      fullname: payload.fullname,
      email: payload.email,
      cellphone: payload.cellphone,
      role_name: payload.role,
      companies: payload.company ? [parseInt(payload.company)] : [],
      sites: [],
      mdps: []
    };
    const response = await request<any, any>({
      method: 'POST',
      url,
      body: fixed_payload
    });
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async getCompanies(): Promise<ResponseGeneric<any>> {
    const url = `/get_companies`;
    const response = await request<any, any>({ method: 'POST', url });
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async getCompaniesTable(): Promise<ResponseGeneric<any>> {
    const url = `/get_companies_table`;

    const response = await request<any, any>({ method: 'POST', url });
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async getCompaniesByUser(
    payload: GetCompaniesByUserRequest
  ): Promise<ResponseGeneric<any>> {
    const url = `/get_companies_by_id`;

    const response = await request<any, any>({
      method: 'POST',
      url,
      body: payload
    });
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async uploadImage(
    payload: UploadImageRequest
  ): Promise<ResponseGeneric<any>> {
    const url = `/update_image`;

    const response = await request<any, any>({
      method: 'POST',
      url,
      body: payload
    });
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async getAmpsDataGraph(
    payload: GetAmpsDataRequest
  ): Promise<ResponseGeneric<any>> {
    const url = `/get_chart1_A`;
    const response = await request<any, any>({
      method: 'POST',
      url,
      body: payload
    });
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async getDataGraph(
    url: string,
    payload: GetChartData
  ): Promise<ResponseGeneric<any>> {
    const response = await request<any, any>({
      method: 'POST',
      url,
      body: payload
    });
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async getAmpsDataAGraph(
    payload: GetAmpsDataRequest
  ): Promise<ResponseGeneric<any>> {
    const url = `/get_chart1_A(A)_v2`;
    const response = await request<any, any>({
      method: 'POST',
      url,
      body: payload
    });
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async getAmpsDataBGraph(
    payload: GetAmpsDataRequest
  ): Promise<ResponseGeneric<any>> {
    const url = `/get_chart1_B(A)_v2`;
    const response = await request<any, any>({
      method: 'POST',
      url,
      body: payload
    });
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async getAmpsDataCGraph(
    payload: GetAmpsDataRequest
  ): Promise<ResponseGeneric<any>> {
    const url = `/get_chart1_C(A)_v2`;
    const response = await request<any, any>({
      method: 'POST',
      url,
      body: payload
    });
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async getAmpsDataNGraph(
    payload: GetAmpsDataRequest
  ): Promise<ResponseGeneric<any>> {
    const url = `/get_chart1_N(A)_v2`;
    const response = await request<any, any>({
      method: 'POST',
      url,
      body: payload
    });
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async getVoltsDataAGraph(
    payload: GetVoltsDataRequest
  ): Promise<ResponseGeneric<any>> {
    const url = `/get_chart1_A(V)_v2`;
    const response = await request<any, any>({
      method: 'POST',
      url,
      body: payload
    });
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async getVoltsDataBGraph(
    payload: GetVoltsDataRequest
  ): Promise<ResponseGeneric<any>> {
    const url = `/get_chart1_B(V)_v2`;
    const response = await request<any, any>({
      method: 'POST',
      url,
      body: payload
    });
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async getVoltsDataCGraph(
    payload: GetVoltsDataRequest
  ): Promise<ResponseGeneric<any>> {
    const url = `/get_chart1_C(V)_v2`;
    const response = await request<any, any>({
      method: 'POST',
      url,
      body: payload
    });
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async getVoltsDataGGraph(
    payload: GetVoltsDataRequest
  ): Promise<ResponseGeneric<any>> {
    const url = `/get_chart1_G(V)_v2`;
    const response = await request<any, any>({
      method: 'POST',
      url,
      body: payload
    });
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async getVoltsDataGraph(
    payload: GetVoltsDataRequest
  ): Promise<ResponseGeneric<any>> {
    const url = `/get_chart1_V`;
    const response = await request<any, any>({
      method: 'POST',
      url,
      body: payload
    });
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async getPowerActiveDataGraph(
    payload: GetPowerDataRequest
  ): Promise<ResponseGeneric<any>> {
    const url = `/get_chart3_Active`;
    const response = await request<any, any>({
      method: 'POST',
      url,
      body: payload
    });
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async getPowerFactorDataGraph(
    payload: GetPowerDataRequest
  ): Promise<ResponseGeneric<any>> {
    const url = `/get_chart3_Factor`;
    const response = await request<any, any>({
      method: 'POST',
      url,
      body: payload
    });
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async updateCompany({
    id,
    payload
  }: {
    id: number;
    payload: FormData;
  }): Promise<ResponseGeneric<any>> {
    const url = `/company/${id}`;

    const response = await request<any, any>({
      method: 'PUT',
      url,
      body: payload
    });
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async getPowerApparentDataGraph(
    payload: GetPowerDataRequest
  ): Promise<ResponseGeneric<any>> {
    const url = `/get_chart3_Apparent`;
    const response = await request<any, any>({
      method: 'POST',
      url,
      body: payload
    });
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async getPowerReactiveDataGraph(
    payload: GetPowerDataRequest
  ): Promise<ResponseGeneric<any>> {
    const url = `/get_chart3_Reactive`;
    const response = await request<any, any>({
      method: 'POST',
      url,
      body: payload
    });
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async companyDetail(payload: any): Promise<ResponseGeneric<any>> {
    const url = `/company_detail`;

    const response = await request<any, any>({
      method: 'POST',
      url,
      body: payload
    });
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async getPowerLine1DataGraph(
    payload: GetPowerDataRequest
  ): Promise<ResponseGeneric<any>> {
    const url = `/get_chart3_V1`;
    const response = await request<any, any>({
      method: 'POST',
      url,
      body: payload
    });
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async updateSite(payload: any): Promise<ResponseGeneric<any>> {
    const url = `/site/${payload.site_id}`;
    const response = await request<any, any>({
      method: 'PUT',
      url,
      body: payload
    });

    return response.data;
  }

  static async listGMTs(): Promise<ResponseGeneric<any>> {
    const url = `/get_timezone_list`;

    const response = await request<any, any>({ method: 'POST', url });
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async getPowerLine2DataGraph(
    payload: GetPowerDataRequest
  ): Promise<ResponseGeneric<any>> {
    const url = `/get_chart3_V2`;
    const response = await request<any, any>({
      method: 'POST',
      url,
      body: payload
    });
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async updateMDP(payload: any): Promise<ResponseGeneric<any>> {
    const url = `/mdp/${payload.id}`;
    const response = await request<any, any>({
      method: 'PUT',
      url,
      body: payload
    });

    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response.data;
  }

  static async getPowerLine3DataGraph(
    payload: GetPowerDataRequest
  ): Promise<ResponseGeneric<any>> {
    const url = `/get_chart3_V3`;
    const response = await request<any, any>({
      method: 'POST',
      url,
      body: payload
    });
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async updateUserDetails(payload: any): Promise<ResponseGeneric<any>> {
    const url = `/edit_user`;

    const response = await request<any, any>({
      method: 'POST',
      url,
      body: payload
    });
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async getHarmonicAmpsLine1DataGraph(
    payload: GetPowerDataRequest
  ): Promise<ResponseGeneric<any>> {
    const url = `/get_chart2_A1`;
    const response = await request<any, any>({
      method: 'POST',
      url,
      body: payload
    });
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async getHarmonicAmpsLine2DataGraph(
    payload: GetPowerDataRequest
  ): Promise<ResponseGeneric<any>> {
    const url = `/get_chart2_A2`;
    const response = await request<any, any>({
      method: 'POST',
      url,
      body: payload
    });
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async getHarmonicAmpsLine3DataGraph(
    payload: GetPowerDataRequest
  ): Promise<ResponseGeneric<any>> {
    const url = `/get_chart2_A3`;
    const response = await request<any, any>({
      method: 'POST',
      url,
      body: payload
    });
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async getHarmonicVoltLine1DataGraph(
    payload: GetPowerDataRequest
  ): Promise<ResponseGeneric<any>> {
    const url = `/get_chart2_V1`;
    const response = await request<any, any>({
      method: 'POST',
      url,
      body: payload
    });
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async getHarmonicVoltLine2DataGraph(
    payload: GetPowerDataRequest
  ): Promise<ResponseGeneric<any>> {
    const url = `/get_chart2_V2`;
    const response = await request<any, any>({
      method: 'POST',
      url,
      body: payload
    });
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async getHarmonicVoltLine3DataGraph(
    payload: GetPowerDataRequest
  ): Promise<ResponseGeneric<any>> {
    const url = `/get_chart2_V3`;
    const response = await request<any, any>({
      method: 'POST',
      url,
      body: payload
    });
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async createMDP(payload: any): Promise<ResponseGeneric<any>> {
    const url = `/mdp`;

    const response = await request<any, any>({
      method: 'POST',
      url,
      body: payload
    });
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async createSite(payload: any): Promise<ResponseGeneric<any>> {
    const url = `/site`;
    const response = await request<any, any>({
      method: 'POST',
      url,
      body: payload
    });
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }
  static async getDevicesTable(payload: any): Promise<ResponseGeneric<any>> {
    const url = `/get_device_list`;
    const response = await request<any, any>({
      method: 'POST',
      url,
      body: payload
    });
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async updateDeviceDetails(
    payload: any
  ): Promise<ResponseGeneric<any>> {
    const url = `/update_device`;
    const response = await request<any, any>({
      method: 'POST',
      url,
      body: payload
    });
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async uploadFile(
    payload: UploadFileRequest
  ): Promise<ResponseGeneric<any>> {
    const url = `/upload_file`;
    const response = await request<any, any>({
      method: 'POST',
      url,
      body: payload
    });
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async uploadFileData(payload: any): Promise<ResponseGeneric<any>> {
    const url = `/register_file_company`;

    const response = await request<any, any>({
      method: 'POST',
      url,
      body: payload
    });
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async getListFilesCompany(
    payload: ListFilesCompanyRequest
  ): Promise<ResponseGeneric<any>> {
    const url = `/get_list_files_company`;

    const response = await request<any, any>({
      method: 'POST',
      url,
      body: payload
    });
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async saveFCMToken(
    payload: SetFCMTokenRequest
  ): Promise<ResponseGeneric<any>> {
    const url = `/upload_fcmtoken`;
    const response = await request<any, any>({
      method: 'POST',
      url,
      body: payload
    });
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async getNotifications(): Promise<ResponseGeneric<any>> {
    const url = `/get_alert_history`;
    const response = await request<any, any>({ method: 'POST', url });
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }
  static async getConfigByMdp(mdpId: number): Promise<ResponseGeneric<any>> {
    const url = `/get_json_mdp`;
    const response = await request<any, any>({
      method: 'POST',
      url,
      body: {
        mdp_id: mdpId
      }
    });
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async deleteConfigByMdpAlert(
    alertId: number
  ): Promise<ResponseGeneric<any>> {
    const url = `/delete_alert`;
    const response = await request<any, any>({
      method: 'POST',
      url,
      body: {
        id: alertId
      }
    });
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async deleteConfigByMdpEvent(
    eventId: number
  ): Promise<ResponseGeneric<any>> {
    const url = `/delete_event`;
    const response = await request<any, any>({
      method: 'POST',
      url,
      body: {
        id: eventId
      }
    });
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async setConfigByMdp(
    mdpId: number,
    data: any
  ): Promise<ResponseGeneric<any>> {
    const url = `/upload_json_mdp`;
    console.log('data : ', data);
    const response = await request<any, any>({
      method: 'POST',
      url,
      body: {
        mdp_id: mdpId,
        mdp_json: data
      }
    });
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async deleteMdp(id: number) {
    const url = `/mdp/${id}`;

    const response = await request<any, any>({
      method: 'DELETE',
      url
    });

    if (response.isError) throw new Error(response.error!);

    return response.data;
  }

  static async deleteSite(id: number) {
    const url = `/site/${id}`;

    const response = await request<any, any>({
      method: 'DELETE',
      url
    });

    if (response.isError) throw new Error(response.error!);

    return response.data;
  }

  static async deleteUser(id: number) {
    const url = `/user/${id}`;

    const response = await request<any, any>({
      method: 'DELETE',
      url
    });

    if (response.isError) throw new Error(response.error!);
    return response.data;
  }

  static async getMdpValues(
    ids: number[],
    from: moment.Moment,
    to: moment.Moment
  ) {
    try {
      const url = `/mdp/values?from=${from.unix()}&to=${to.unix()}`;

      const response = await request<any, any>({
        method: 'POST',
        url,
        body: {
          mdpIds: ids
        }
      });
      //TODO: validate after
      return response.data;
    } catch (error) {
      return {
        success: false
      };
    }
  }

  static async deleteFile(fileId: number) {
    const url = `/file/${fileId}`;

    const response = await request<any, any>({
      method: 'DELETE',
      url
    });

    if (response.isError) throw new Error(response.error!);

    return response.data;
  }

  static async setSiteAsDefault(siteId: number) {
    const url = `/site/${siteId}/default`;

    const response = await request<any, any>({
      method: 'POST',
      url
    });

    if (response.isError) throw new Error(response.error!);

    return response.data;
  }

  static async getMdpSystemInfo(mdpId: string) {
    try {
      const url = `/mdp/${mdpId}/system_info`;
      const response = await request<any, any>({
        method: 'GET',
        url
      });

      //TODO: validate
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  static async getMdpOverview(
    mdpId: string,
    query: { to: string; from: string }
  ) {
    try {
      const url = `/mdp/${mdpId}/overview?date_from=${query.from}&date_to=${query.to}`;

      const response = await request<any, any>({
        method: 'GET',
        url
      });
      //TODO: validate
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  static async getOverviewChartMdp(
    mdpId: string,
    query: { to: string; from: string }
  ) {
    try {
      const url = `/mdp/${mdpId}/overview-plot?date_from=${query.from}&date_to=${query.to}`;

      const response = await request<any, any>({
        method: 'GET',
        url
      });
      //TODO: validate
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  static async createCompanyV2(values: FormData) {
    const url = `/company`;

    try {
      const response = await request<any, any>({
        method: 'POST',
        url,
        body: values
      });

      if (response.isError) throw new Error(response.error!);

      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async createSiteV2(values: FormData) {
    const url = `/site`;

    try {
      const response = await request<any, any>({
        method: 'POST',
        url,
        body: values
      });

      if (response.isError) throw new Error(response.error!);

      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async createDeviceV2(values: any) {
    const url = `/mdp`;

    try {
      const response = await request<any, any>({
        method: 'POST',
        url,
        body: values
      });

      if (response.isError) {
        toast.error(response.error, {
          position: 'bottom-right'
        });
      }

      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async createUserV2(values: any) {
    const url = `/v1/user`;

    try {
      const response = await request<any, any>({
        method: 'POST',
        url,
        body: values
      });

      if (response.isError) {
        toast.error(response.error, {
          position: 'bottom-right'
        });
      }

      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async updateSiteV2(values: any, id: number) {
    const url = `/site/${id}`;

    try {
      const response = await request<any, any>({
        method: 'PUT',
        url,
        body: values
      });

      if (response.isError) {
        toast.error(response.error, {
          position: 'bottom-right'
        });
      }

      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async getUsersForSite(siteId: string, companyId: string) {
    const url = `/v1/company/${companyId}/site/${siteId}/users?scope=site`;

    try {
      const response = await request<any, any>({
        method: 'GET',
        url
      });

      if (response.isError) {
        toast.error(response.error, {
          position: 'bottom-right'
        });
      }

      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async assignUsersForSite(siteId: string, payload: any) {
    const url = `/v1/site/${siteId}/users`;
    try {
      const response = await request<any, any>({
        method: 'POST',
        url,
        body: payload
      });

      if (response.isError) {
        toast.error(response.error, {
          position: 'bottom-right'
        });
      }

      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async getVCPlotData(
    meterId: string,
    start: string,
    end: string,
    avgLevel: string
  ) {
    try {
      const url = `/v1/meter/${meterId}/plot-vc?from=${start}&to=${end}&avg_level=${avgLevel}`;

      const response = await request<any, any>({
        method: 'GET',
        url
      });

      console.log('🚀 ~ ElectripureService ~ response:', response);

      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  static async getPowerPlotData(
    meterId: string,
    start: string,
    end: string,
    avgLevel: string
  ) {
    try {
      const url = `/v1/meter/${meterId}/plot-p?from=${start}&to=${end}&avg_level=${avgLevel}`;

      const response = await request<any, any>({
        url,
        method: 'GET'
      });

      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  static async getVHPlotData(
    meterId: string,
    start: string,
    end: string,
    avgLevel: string
  ) {
    try {
      const url = `/v1/meter/${meterId}/plot-vh?from=${start}&to=${end}&avg_level=${avgLevel}`;
      const response = await request<any, any>({
        method: 'GET',
        url
      });

      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  static async getCHPlotData(
    meterId: string,
    start: string,
    end: string,
    avgLevel: string
  ) {
    const token = localStorage.getItem(LOCAL_STORAGE.TOKEN);
    console.log({ token });
    try {
      const url = `/v1/meter/${meterId}/plot-ch?from=${start}&to=${end}&avg_level=${avgLevel}`;
      const response = await request<any, any>({
        url,
        method: 'GET'
      });

      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
}
