import { VOLTAGE_CURRENT_CHANNEL } from '@/components/TimeSeriesPlots/constants/enum';

type ChartLegend = {
  legends: {
    backgroundColor: string;
    label: string;
  }[];
};

export const ChartLegend = ({ legends }: ChartLegend) => {
  return (
    <div className="w-full flex gap-x-4 gap-y-1 flex-wrap">
      {legends.map((l, index) => (
        <div key={index} className="flex items-center gap-x-2">
          {l.label === '3 Phase Average' ||
          l.label === VOLTAGE_CURRENT_CHANNEL.IMBALANCE ||
          l.label === VOLTAGE_CURRENT_CHANNEL.MAX_A ||
          l.label === VOLTAGE_CURRENT_CHANNEL.MAX_B ||
          l.label === VOLTAGE_CURRENT_CHANNEL.MAX_C ? (
            <div className="flex gap-x-[3px]">
              <span
                className="h-1 w-3 block rounded-l-sm"
                style={{ background: l.backgroundColor }}
              />
              <span
                className="h-1 w-3 block rounded-r-sm"
                style={{ background: l.backgroundColor }}
              />
            </div>
          ) : l.label === VOLTAGE_CURRENT_CHANNEL.MIN_A ||
            l.label === VOLTAGE_CURRENT_CHANNEL.MIN_B ||
            l.label === VOLTAGE_CURRENT_CHANNEL.MIN_C ? (
            <div className="flex gap-x-[2px]">
              <span
                className="h-1 w-2
                 block rounded-l-sm"
                style={{ background: l.backgroundColor }}
              />
              <span
                className="h-1 w-1 rounded"
                style={{ background: l.backgroundColor }}
              />
              <span
                className="h-1 w-2
                 block rounded-r-sm"
                style={{ background: l.backgroundColor }}
              />
            </div>
          ) : (
            <span
              className="h-1 w-6 block rounded-sm"
              style={{ background: l.backgroundColor }}
            />
          )}

          <span
            className="text-xs font-bold"
            style={{ color: l.backgroundColor }}>
            {l.label}
          </span>
        </div>
      ))}
    </div>
  );
};
