import { getLocalStorage } from '@/functions/local-storage.function';
import { LOCAL_STORAGE } from '@/types/enum/local-storage.enum';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { login, setSession } from '../actions/electripure';
import Card from '../components/Card';
import LoginForm from '../components/Form/LoginForm';
import { ButtonSecondary } from '../components/FormInput/Button';
import Navbar from '../components/Navbar';
import Space from '../components/Space';
import { TYPE_SPACE } from '../config/enum';
import { LoginDataForm } from '../interfaces/form';
import { ElectripureState } from '../interfaces/reducers';

function LoginPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const electripureJwt =
    useSelector((state: ElectripureState) => state.electripureJwt) ||
    getLocalStorage(LOCAL_STORAGE.ACCESS);

  const loginToken = useSelector((state: ElectripureState) => state.loginToken);

  useEffect(() => {
    if (electripureJwt) {
      dispatch(
        setSession({
          jwt: electripureJwt
        })
      );
    }
  }, []);

  useEffect(() => {
    if (electripureJwt) {
      navigate(`/dashboard/user/detail/me`);
    }
  }, [electripureJwt]);

  useEffect(() => {
    if (loginToken) {
      navigate(`/login/verify/select`);
    }
  }, [loginToken]);

  function submitLoginForm(data: LoginDataForm) {
    dispatch(
      login({
        email: data.email,
        password: data.password,
        rememberToken: data.remember ? data.rememberToken : null,
        remember: data.remember
      })
    );
  }

  function forgotPassword() {
    navigate(`/reset`);
  }

  return (
    <Navbar>
      <div className="w-full max-w-[430px]">
        <Space type={TYPE_SPACE.FORM_DISTANCE} />
        <Card>
          <div className="px-[50px] pt-[20px] pb-[40px]">
            <LoginForm
              onSubmit={submitLoginForm}
              forgotPassword={forgotPassword}
            />
            <Space type={TYPE_SPACE.FORM_DISTANCE} />
            <div className="justify-center items-center flex">
              <span className="color-black-dark text-sm">
                Don’t have an account?
              </span>
            </div>
            <Space type={TYPE_SPACE.INPUT_DISTANCE} />
            <div className="justify-center items-center flex w-[130px] mx-auto">
              <ButtonSecondary onClick={() => {}}>Contact us</ButtonSecondary>
            </div>
          </div>
        </Card>
      </div>
    </Navbar>
  );
}

export default LoginPage;
