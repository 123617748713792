import { ChannelMenuTimeSeriesPlots } from './components/ChannelMenuTimeSeriesPlots';
import { EventMenuTimeSeriesPlots } from './components/eventMenuTimeSeriesPlots';
import ExportData from './components/exportData';
import { TabsTimeSeriesPlots } from './components/tabsTimeSeriesPlots';
import { TimeRangeCompany } from './components/timeRange';

export function HeaderTimeSeriesPlots() {
  return (
    <div className="flex items-center w-full flex-wrap gap-y-2">
      <div className="flex gap-x-2">
        <TimeRangeCompany />
        <TabsTimeSeriesPlots className="" />
      </div>
      <div className="flex items-center flex-grow xl:justify-between">
        <div className="flex ">
          <EventMenuTimeSeriesPlots optionEvent="" />
          <ChannelMenuTimeSeriesPlots />
        </div>
        <ExportData />
      </div>
    </div>
  );
}
