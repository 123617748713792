import { useDispatch } from 'react-redux';
import {
  setJwt,
  setLoginToken,
  setTimestampTwoStepVerification
} from './../../../../actions/electripure';

// images
import { removeLocalStorage } from '@/functions/local-storage.function';
import { LOCAL_STORAGE } from '@/types/enum/local-storage.enum';
import { useNavigate } from 'react-router-dom';
import { PERMISSIONS } from '../../../../utils/roles';
import companyImg from './../assets/company_management.svg';
import deviceImg from './../assets/device_management.svg';
import logoutImg from './../assets/logout.svg';
import userImg from './../assets/users_management.svg';
import { UserSettingsOption } from './UserSettingsOption';

const UserSettings = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const options = [
    {
      to: '/dashboard/company/list',
      label: 'Company Management',
      allowedPermissions: [PERMISSIONS.VIEW_COMPANY],
      icon: companyImg
    },
    {
      to: '/dashboard/user/list',
      label: 'Users Management',
      allowedPermissions: [PERMISSIONS.VIEW_USER],
      icon: userImg
    },
    {
      to: '/dashboard/admin/overview',
      label: 'Admin Overview',
      allowedPermissions: [PERMISSIONS.VIEW_MDP],
      icon: deviceImg
    },
    {
      to: '/dashboard/device/list',
      label: 'Device Management',
      allowedPermissions: [PERMISSIONS.VIEW_MDP],
      icon: deviceImg
    }
  ];

  function logout() {
    dispatch(
      setTimestampTwoStepVerification({
        timestamp: null
      })
    );
    dispatch(
      setLoginToken({
        token: null
      })
    );
    dispatch(
      setJwt({
        token: null
      })
    );
    removeLocalStorage(LOCAL_STORAGE.ACCESS);
    removeLocalStorage(LOCAL_STORAGE.USER_ID);
    removeLocalStorage(LOCAL_STORAGE.USER_FULLNAME);

    removeLocalStorage(LOCAL_STORAGE.REFRESH);
    removeLocalStorage(LOCAL_STORAGE.USER_DATA);
    navigate('/login');
  }

  return (
    <>
      {options.map((option, index) => (
        <UserSettingsOption key={index} {...option} />
      ))}
      <div
        onClick={logout}
        className="flex hover:bg-slate-100 rounded-lg cursor-pointer">
        <img src={logoutImg} alt="" />
        <p className="m-[15px]">Logout</p>
      </div>
    </>
  );
};

export default UserSettings;
